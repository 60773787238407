import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as ROUTES from "../../constants/routes";
import LoginBG from "../../images/login-bg.jpg";
import Logo from "../../images/ydentity-logo.jpg";
import { withFirebase } from "../Firebase";
import "./index.css";
import { AuthUserContext } from "../Session";
import qs from "qs";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://ydentity.com">
        Ydentity
      </Link>{" "}
      {new Date().getFullYear()}
      {": All right reserved"}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: "url(" + LoginBG + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  ydLogo: {
    width: "134.5px",
    height: "40.2px",
    marginBottom: "30px"
  },
  loginTitle: {
    marginBottom: theme.spacing(3)
  },
  h1: {
    fontFamily: "Lato",
    fontSize: "35px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#2a2a2a"
  },
  activeButton: {
    height: "50px",
    borderRadius: "3px",
    backgroundColor: "#0b8af0",
    margin: theme.spacing(3, 0, 2)
  },
  forgottenYourPassword: {
    fontFamily: "Lato",
    fontSize: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#0b8af0"
  },
  errorMessage: {
    color: "red"
  }
}));

const INITIAL_STATE = {
  error: null
};

function Land(props) {
  const classes = useStyles();

  let [email, setEmail] = useState(null);
  let [password, setPassword] = useState(null);
  let [error, setError] = useState(null);

  const onSubmit = event => {
    event.preventDefault();

    if (email == null || password == null) {
      return;
    }

    props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(authUser => {
        // Create a user in your Firebase firestore
        console.log("LOGIN USER");

        var date = new Date();

        return props.firebase
          .user(authUser.user.uid)
          .set({ lastLogin: date.toISOString() }, { merge: true });
      })
      .then(() => {
        props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        setError({ error });
      });
  };

  const onChangeEmail = event => {
    let { name, value } = event.target;
    setEmail(value);
  };
  const onChangePassword = event => {
    let { name, value } = event.target;
    setPassword(value);
  };

  const userContext = useContext(AuthUserContext);

  if(userContext) {
    const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    console.log(queryParams);
    if(queryParams.redirectUrl)
      props.history.push(queryParams.redirectUrl);
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={Logo} className={classes.ydLogo} />
          <Typography
            component="h1"
            variant="h5"
            className={[classes.h1, classes.loginTitle].join(" ")}
          >
            Start Login
          </Typography>
          {error && (
            <p className={classes.errorMessage}>{error.error.message}</p>
          )}
          <form className={classes.form} onSubmit={onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              onChange={onChangeEmail}
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              onChange={onChangePassword}
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.activeButton}
            >
              Login
            </Button>
            <Grid container>
              <Grid item xs>
                <Link
                  href={ROUTES.PASSWORD_FORGET}
                  className={classes.forgottenYourPassword}
                >
                  {"Forgotten your password?"}
                </Link>
              </Grid>
              <Grid item>
                <Link
                  href={ROUTES.SIGN_UP}
                  variant="body2"
                  className={classes.forgottenYourPassword}
                >
                  {"Don’t have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={2}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default compose(
  withRouter,
  withFirebase
)(Land);
