import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as ROUTES from "../../constants/routes";
import SignupBG from "../../images/signup-bg.jpg";
import LogoWhite from "../../images/ydentity-logo-white.png";
import Logo from "../../images/ydentity-logo.jpg";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import "./index.css";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://ydentity.com">
        Ydentity
      </Link>{" "}
      {new Date().getFullYear()}
      {": All right reserved"}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: "url(" + SignupBG + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    overflow: 'hidden'
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    height: "50px",
    borderRadius: "3px",
    backgroundColor: "#0b8af0",
    margin: theme.spacing(3, 0, 2)
  },
  ydLogo: {
    width: "134.5px",
    height: "40.2px",
    marginBottom: "30px"
  },
  bigAvatar: {
    margin: theme.spacing(1),
    width: 60,
    height: 60
  },
  loginTitle: {
    marginBottom: theme.spacing(3)
  },
  h1: {
    fontFamily: "Lato",
    fontSize: "35px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#2a2a2a"
  },
  errorMessage: {
    color: "red"
  },
  oneStep: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    marginLeft: "80px"
  },
  oneStepMore: {
    fontSize: "35px",
    fontWeight: 500,
    marginBottom: "30px"
  },
  div1: {
    display: "table",
    width: "50%",
    height: "80%"
  },
  div2: {
    display: "table-cell",
    verticalAlign: "middle"
  },
  div3: {
    textAlign: "left"
  }
}));

const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

const ERROR_MSG_ACCOUNT_EXISTS = `An account with this E-Mail address already exists.`;

function Land(props) {
  const classes = useStyles();

  let [email, setEmail] = useState(null);
  let [phone, setPhone] = useState(null);
  let [password, setPassword] = useState(null);
  let [firstName, setFirstName] = useState(null);
  let [lastName, setLastName] = useState(null);
  let [error, setError] = useState(null);

  const onSubmit = event => {
    event.preventDefault();

    if (
      email == null ||
      password == null ||
      firstName == null ||
      lastName == null
    ) {
      return;
    }

    props.firebase
      .doCreateUserWithEmailAndPassword(email, password)
      .then(authUser => {
        // Create a user in your Firebase firestore
        console.log("CREATE USER");
        var date = new Date();

        return props.firebase.user(authUser.user.uid).set(
          {
            email: email,
            phone: phone,
            lastLogin: date.toISOString(),
            firstName: firstName,
            lastName: lastName
          },
          { merge: true }
        );
      })
      .then(() => {
        console.log("SEND EMAIL");
        return props.firebase.doSendEmailVerification();
      })
      .then(() => {
        props.history.push(ROUTES.HOME);
      })
      .catch(error => {
        if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
          error.message = ERROR_MSG_ACCOUNT_EXISTS;
        }
        console.log(error);

        setError({ error });
      });
  };

  const onChangeEmail = event => {
    let { name, value } = event.target;
    setEmail(value);
  };
  const onChangePhone = event => {
    let { phone, value } = event.target;
    setPhone(value);
  }
  const onChangePassword = event => {
    let { name, value } = event.target;
    setPassword(value);
  };
  const onChangeFirstName = event => {
    let { name, value } = event.target;
    setFirstName(value);
  };
  const onChangeLastName = event => {
    let { name, value } = event.target;
    setLastName(value);
  };

  {
    error && console.log("ERROR: " + JSON.stringify(error));
  }

  const userContext = useContext(AuthUserContext);

  if(userContext) {
    props.history.push(ROUTES.HOME);
  }

  return (
    <Grid container component="main" className={classes.root}>
      <Hidden smDown>
      <Grid item xs={false} sm={4} md={7} className={classes.image}>
        <div className={classes.div1}>
          <div className={classes.div2}>
            <div className={classes.div3}>
              <img
                src={LogoWhite}
                className={classes.ydLogo}
                style={{ marginLeft: "80px" }}
              />
              <Typography
                component="h1"
                variant="h5"
                className={[classes.oneStep, classes.oneStepMore].join(" ")}
              >
                You are one step away from knowing if your data has been
                breached!
              </Typography>
              <Typography
                component="h1"
                variant="h5"
                className={[classes.oneStep].join(" ")}
              >
                Take back control of you data with our ultra-secure blockchain
                platform.
              </Typography>
            </div>
          </div>
        </div>
      </Grid>
      </Hidden>
      <Grid item sm={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img src={Logo} className={classes.ydLogo} />

          <Typography
            component="h1"
            variant="h5"
            className={[classes.h1, classes.loginTitle].join(" ")}
          >
            Sign up for your free ydentity account
          </Typography>

          {error && (
            <p className={classes.errorMessage}>{error.error.message}</p>
          )}

          <form className={classes.form} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  onChange={onChangeFirstName}
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="lname"
                  name="lastName"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  onChange={onChangeLastName}
                  id="lastName"
                  label="Last Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  onChange={onChangeEmail}
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="phone"
                  onChange={onChangePhone}
                  label="Phone"
                  name="phone"
                  autoComplete="phone"
                  placeholder="(Optional) Search for more results with your phone number"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  onChange={onChangePassword}
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="allowExtraEmails"
                      color="primary"
                      required
                    />
                  }
                  label={
                    <div style={{ zIndex: 3 }}>
                      <span>I accept the </span>{" "}
                      <Link href={"/terms"} target="_blank">terms & conditions</Link>
                    </div>
                  }
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign Up
            </Button>

            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default compose(
  withRouter,
  withFirebase
)(Land);
