import Button from "@material-ui/core/Button";
import React from "react";
import IconSignout from "../../images/icon-signout.svg";
import { withFirebase } from "../Firebase";

const SignOutButton = ({ firebase }) => (
  <Button
    color="inherit"
    onClick={firebase.doSignOut}
    style={{
      fontFamily: "Lato",
      fontSize: "16px",
      fontWeight: "bold",
      fontStyle: "normal",
      fontStretch: "normal",
      lineHeight: "normal",
      letterSpacing: "normal",
      textAlign: "right",
      color: "#ffffff",
      textTransform: "none"
    }}
  >
    <img src={IconSignout} style={{ paddingRight: "10px" }} />
    Sign Out
  </Button>
);

export default withFirebase(SignOutButton);
