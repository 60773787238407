import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconWelcome from "../../../images/icon-welcome.svg";
import UpsellBG from "../../../images/upsell-bg.jpg";
import UpsellLI from "../../../images/upsell-li.svg";
import BreachBreakdown from "./BreachBreakdown";
import "./index.css";
import Upsell from "../../Upsell";
import HowYourReportWorks from "../HowYourReportWorks";
import FAQ from "../FAQ";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#fafafa",
    flexGrow: 1,
    position: 'relative'
  },
  contentContainer: {
    paddingTop: theme.spacing(3)
  },

  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: "flex"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: "80%",
    height: "75px",
    borderRadius: "8px",
    padding: "20px",
    objectFit: "scale-down",
    background: "#e0e0e0"
  },
  divider: {
    height: "1px",
    backgroundColor: "#e9e9e9"
  },

  breachesBigText: {
    fontSize: "54px",
    fontWeight: 500
  },

  breachesPaper: {
    padding: theme.spacing(3, 2)
  },
  breachesText: {
    fontFamily: "Lato",
    fontSize: "20px",
    display: "block",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  summaryText: {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "rgba(35, 38, 43, 0.65)"
  },
  summaryTextBold: {
    fontWeight: 500
  },
  breachDateText: {
    fontWeight: 300
  },
  breachDescriptionText: {
    opacity: 0.65,
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.69,
    letterSpacing: "normal",
    color: "#23262b"
  },

  upsell: {
    backgroundImage: "url(" + UpsellBG + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    background: "#05c3be",
    width: "100%"
  },
  upsellh2: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellh1: {
    fontSize: "35px",
    fontWeight: 500
  },
  upsellGrid: {
    padding: theme.spacing(4, 4)
  },
  upsellPrice: {
    fontFamily: "Lato",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff"
  },
  upsellLargeText: {
    fontSize: "64px"
  },
  upsellMonthlyText: {
    fontSize: "24px"
  },
  upsellFeaturesDivider: {
    height: "1px",
    opacity: 0.3,
    backgroundColor: "#ffffff"
  },
  upsellFeaturesTitle: {
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellFeaturesList: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.88,
    letterSpacing: "normal",
    color: "#ffffff",
    listStyleImage: "url(" + UpsellLI + ")"
  },

  upsellButton: {
    height: "50px",
    borderRadius: "3px",
    backgroundColor: "#0b8af0",
    margin: theme.spacing(3, 0, 2)
  },

  welcomeText: {
    fontFamily: "Lato",
    fontSize: "35px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  titleText: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },
}));

const Breaches = (props) => {
  const classes = useStyles();

  const activeSubscription = () => {
    if(!props.user.subscription)
      return false;
    return props.user.subscription.active;
  }

  return <div>
              <Container maxWidth={false}>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  className={classes.contentContainer}
                >
                  <HowYourReportWorks />
                  <Grid item container direction="column" spacing={3} md={6} style={{margin: 'auto'}}>
                    <Grid item container spacing={2}>
                      <Grid item container md={12} spacing={2}>
                        <Grid item>
                          <img src={IconWelcome} className={classes.ydLogo} />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="h4"
                            component="h1"
                            className={classes.welcomeText}
                          >
                            Welcome {props.user.firstName} {props.user.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item md={12}>
                        <div className={classes.divider}></div>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      {props.acurisBreaches.contact.length > 0 ?
                        <BreachBreakdown breachTitle="Contact Details" breachType="contact" user={props.user} breachList={props.acurisBreaches.contact} breachBreakdown={[
                          { title: 'Email', key: 'email' },
                          { title: 'Phone', key: 'phone' },
                        ]} />
                      : null }
                    </Grid>
                    <Grid item container spacing={2}>
                      {props.acurisBreaches.personal.length > 0 ?
                        <BreachBreakdown breachTitle="Personal Details" breachType="personal" user={props.user} breachList={props.acurisBreaches.personal} breachBreakdown={[
                          { title: 'Full Name', key: 'fullName' },
                          { title: 'Title', key: 'title' },
                          { title: 'First Name', key: 'firstName' },
                          { title: 'Middle Name', key: 'middleName' },
                          { title: 'Last Name', key: 'lastName' },
                          { title: 'Date of Birth', key: 'dob', obfs: true },
                          { title: 'Date of Birth Day', key: 'dobD', obfs: true },
                          { title: 'Date of Birth Month', key: 'dobM', obfs: true },
                          { title: 'Date of Birth Year', key: 'dobY', obfs: true },
                          { title: 'Place of Birth', key: 'placeOfBirth', obfs: true },
                          { title: 'Gender', key: 'gender', obfs: true }
                        ]} />
                      : null }
                    </Grid>
                    <Grid item container spacing={2}>
                      {props.acurisBreaches.location.length > 0 ?
                        <BreachBreakdown breachTitle="Location Details" breachType="location" user={props.user} breachList={props.acurisBreaches.location} breachBreakdown={[
                          { title: 'City', key: 'city', obfs: true },
                          { title: 'State', key: 'state', obfs: true },
                          { title: 'Post Code', key: 'postCode', obfs: true },
                          { title: 'Country', key: 'country', obfs: true },
                          { title: 'Address', key: 'address', obfs: true },
                        ]} />
                      : null }
                    </Grid>
                    <Grid item container spacing={2}>
                      {props.acurisBreaches.employment.length > 0 ?
                        <BreachBreakdown breachTitle="Employment Details" breachType="employment" user={props.user} breachList={props.acurisBreaches.employment} breachBreakdown={[
                          { title: 'Job Title', key: 'jobTitle', obfs: true },
                          { title: 'Employer', key: 'employer', obfs: true },
                        ]} />
                      : null }
                    </Grid>
                    <Grid item container spacing={2}>
                      {props.acurisBreaches.id.length > 0 ?
                        <BreachBreakdown breachTitle="Identification" breachType="identification" user={props.user} breachList={props.acurisBreaches.id} breachBreakdown={[
                          { title: 'Drivers License', key: 'driversLicense', obfs: true },
                          { title: 'Passport Number', key: 'passportNumber', obfs: true },
                        ]} />
                      : null }
                    </Grid>
                    <Grid item container spacing={2}>
                      {props.acurisBreaches.paymentDetails.length > 0 ?
                        <BreachBreakdown breachTitle="Payment Details" breachType="payment" user={props.user} breachList={props.acurisBreaches.paymentDetails} breachBreakdown={[
                          { title: 'BIN', key: 'binNumber', obfs: true },
                          { title: 'Card Number', key: 'cardNumber', obfs: true },
                          { title: 'Name on Card', key: 'nameOnCard', obfs: true },
                          { title: 'Expiry Date', key: 'expiryDate', obfs: true },
                          { title: 'Expiry Month', key: 'expiryMonth', obfs: true },
                          { title: 'Expiry Year', key: 'expiryYear', obfs: true },
                          { title: 'Card Type', key: 'cardType', obfs: true },
                          { title: 'CVV', key: 'cvv', obfs: true },
                        ]} />
                      : null }
                    </Grid>
                    <Grid item container spacing={2}>
                      {props.acurisBreaches.secretQs.length > 0 ?
                        <BreachBreakdown breachTitle="Secret Questions" breachType="secret_qs" user={props.user} breachList={props.acurisBreaches.secretQs} breachBreakdown={[
                          { title: 'Question', key: 'question', obfs: true },
                          { title: 'Answer', key: 'answer', obfs: true },
                        ]} />
                      : null }
                    </Grid>
                  </Grid>
                  {activeSubscription() ? <FAQ /> : <Upsell />}
                </Grid>
              </Container>
            </div>

};

export default Breaches;
