export const SIGN_UP = "/signup";
export const LOGIN = "/login";
export const LANDING = "/";
export const HOME = "/home";
export const DEBUG = "/debug";
export const ACCOUNT = "/account";
export const PASSWORD_FORGET = "/forgotten-password";
export const SUBSCRIBE_TO_PREMIUM = "/premium/subscribe";
export const ACCESS_PREMIUM = "/premium/access";
export const FAKE_EXPERIAN = "/experian";
export const TERMS_AND_CONDITIONS = "/terms";
export const DISCOUNT = "/discount";