import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import dompurify from "dompurify";
import React, { useContext, useState } from "react";
import { compose } from "recompose";
import UpsellBG from "../../images/upsell-bg.jpg";
import UpsellLI from "../../images/upsell-li.svg";
import { withFirebase } from "../Firebase";
import { AuthUserContext, withAuthorization } from "../Session";
import { useToasts } from 'react-toast-notifications';

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(3, 2),
    backgroundColor: "#fafafa"
  },
  contentContainer: {
    paddingTop: theme.spacing(3)
  },

  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: "flex"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: "80%",
    height: "75px",
    borderRadius: "8px",
    padding: "20px",
    objectFit: "scale-down",
    background: "#e0e0e0"
  },
  divider: {
    height: "1px",
    backgroundColor: "#e9e9e9"
  },

  welcomeText: {
    fontFamily: "Lato",
    fontSize: "35px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  titleText: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  breachesBigText: {
    fontSize: "54px",
    fontWeight: 500
  },

  breachesPaper: {
    padding: theme.spacing(3, 2)
  },
  breachesText: {
    fontFamily: "Lato",
    fontSize: "20px",
    display: "block",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  summaryText: {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "rgba(35, 38, 43, 0.65)"
  },
  summaryTextBold: {
    fontWeight: 500
  },
  breachDateText: {
    fontWeight: 300
  },
  breachDescriptionText: {
    opacity: 0.65,
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.69,
    letterSpacing: "normal",
    color: "#23262b"
  },
  upsell: {
    backgroundImage: "url(" + UpsellBG + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    background: "#05c3be",
    width: "100%"
  },
  upsellh2: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellh1: {
    fontSize: "35px",
    fontWeight: 500
  },
  upsellGrid: {
    padding: theme.spacing(4, 4)
  },
  upsellFeaturesDivider: {
    height: "1px",
    opacity: 0.3,
    backgroundColor: "#ffffff"
  },
  upsellFeaturesTitle: {
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellFeaturesList: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.88,
    letterSpacing: "normal",
    color: "#ffffff",
    listStyleImage: "url(" + UpsellLI + ")"
  },

  changePassButton: {
    height: "50px",
    borderRadius: "3px",
    backgroundColor: "#0b8af0",
    margin: theme.spacing(3, 0, 2)
  },

  card: {
    marginBottom: '40px',
    marginTop: '40px'
  }
}));

function Account(props) {
  const userContext = useContext(AuthUserContext);
  const classes = useStyles();
  const { addToast } = useToasts();
  let [changingPassword, setChangingPassword] = useState(false);

  var changePassword = (authUser) => {
    setChangingPassword(true);
    props.firebase
      .doPasswordReset(authUser.email)
      .then(() => {
        addToast('Reset password email sent', { appearance: 'success' });
        setChangingPassword(false);
      })
      .catch(() => {
        addToast('Reset password email failed to send', { appearance: 'error' });
        setChangingPassword(false);
      });
  }

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className={classes.root}>
          <Container>
            <Grid
              container
              direction="row"
              spacing={3}
              className={classes.contentContainer}
            >
              <Grid item>
                <Grid item>
                  <Typography
                    variant="h4"
                    className={classes.welcomeText}
                    component="h1"
                  >
                    Account Management
                  </Typography>
                </Grid>

                <Grid item container direction="column">
                  <Grid item style={{width: '100%'}} className={classes.card}>
                    <Button
                      className={classes.changePassButton}
                      fullWidth
                      variant="contained"
                      color="primary"
                      disabled={changingPassword}
                      onClick={() => changePassword(authUser)}>
                        Change Password
                      </Button>
                  </Grid>
                  <Grid item className={classes.card}>
                    For all other account management enquires please contact us at{" "}
                    <Link href="mailto:contact@ydentity.com">
                      contact@ydentity.com
                    </Link>
                    .
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase
)(Account);
