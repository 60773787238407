import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { compose } from "recompose";
import SignupBG from "../../images/signup-bg.jpg";
import UpsellLI from "../../images/upsell-li.svg";
import LogoWhite from "../../images/ydentity-logo-white.png";
import { withFirebase } from "../Firebase";
import "./index.css";
import { AuthUserContext, withAuthorization } from "../Session";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import CircularProgress from '@material-ui/core/CircularProgress';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://ydentity.com">
        Ydentity
      </Link>{" "}
      {new Date().getFullYear()}
      {": All right reserved"}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center'
  },
  terms: {
    margin: 'auto',
    padding: '20px',
    textAlign: 'left'
  },
  bold: {
    fontWeight: 'bold'
  },
  h1: {
    textAlign: 'center',
    marginBottom: '20px'
  }
}));

function TermsAndConditionsPage(props) {
  const classes = useStyles();

  return (
  <Grid container component="main" className={classes.root}>
      <Grid item xs={12} sm={10} md={8} lg={6} component={Paper} elevation={6} className={classes.terms}>
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            className={[classes.h1, classes.loginTitle].join(" ")}
          >
            Ydentity Terms &amp; Conditions
          </Typography>
          <div>
            <p className={classes.bold}>PLEASE READ THE THESE TERMS AND CONDITIONS CAREFULLY, THEY APPLY TO THE USE OF OUR WEB APPLICATION (SERVICES) AND FORM OUR AGREEMENT WITH YOU IN CONNECTION WITH YOUR USE OF THE SERVICES.</p>
            <p className={classes.bold}>IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT PROCEED TO PURCHASE THE SERVICES.</p>

            <p className={classes.bold}>Who we are and how to contact us</p>
            <p>The services we offer can be accessed at: <a href="https://www.ydentity.com/#features">https://www.ydentity.com/#features</a> <span className={classes.bold}>(Services)</span>. The Services are operated by Ydentity Limited, a limited liability company registered in England and Wales with company number 10707700, whose registered offices is at White House, Clarendon Street, Nottingham NG1 5GF <span className={classes.bold}>(Ydentity)</span>. Our VAT Number is: 292 8410 86. To contact us, please email us at: <a href="mailto:hello@ydentity.com">hello@ydentity.com</a>.</p>
            <ol>
              <li><span className={classes.bold}>Who do these terms apply to?</span> These terms apply to anyone who uses or interacts with the Services.</li>
              <li><span className={classes.bold}>Acceptance of these terms.</span> By purchasing and using the Services you confirm that you will comply with the terms and conditions set out below and our data protection and privacy policy which can be found at <a href="https://www.ydentity.com/privacy-policy/">https://www.ydentity.com/privacy-policy/</a>, to the exclusion of all other terms and conditions. We will collect and store your personal data in line with our data protection policy when you use the Services. Our contract with you comes into force once your payment has been received in cleared funds.</li>

              <li><span className={classes.bold}>Payment and use of the Services.</span>
                <ol>
                  <li><span className={classes.bold}>Payment and use during the term of the agreement.</span> Provided you pay us all amounts due to Ydentity when they are due and payable and, comply with these terms and conditions, for the duration of our agreement with you, we grant you a licence to use the Services. You may not sub license the rights we grant to you under this agreement to any other third party.</li>
                  <li>Subject to clause 3.1 above, we will provide the Services during the term of our agreement with you until either your subscription expires, we end our agreement with you, or you end the agreement. Please refer to clauses 4 and 5. </li>
                  <li><span className={classes.bold}>When you must pay and how you must pay.</span> You are signing up to a monthly subscription at the rate advertised. You must set up a monthly direct debit or other monthly payment plan.</li>
                </ol>
              </li>

              <li><span className={classes.bold}>Your rights to cancel or end our agreement with you.</span>
                <ol>
                  <li><span className={classes.bold}>Ending the contract because of something we have done or are going to do.</span> If you are ending our agreement with you for a reason set out at (a) to (e) below the contract will end immediately and you will not be liable to make any further monthly payments for the Services. The reasons are:
                    <ol type="a">
                      <li>we have told you about an upcoming change to the Services which you do not agree to;</li>
                      <li>there is a risk that supply of the Services may be significantly delayed because of events outside our control; </li>
                      <li>we have suspended supply of the Services for technical reasons, or notify you we are going to suspend them for technical reasons, in each case for a period of more than one week; or</li>
                      <li>you have a legal right to end the agreement between us because of something we have done wrong.</li>
                    </ol>
                  </li>

                  <li><span className={classes.bold}>Exercising your right to change your mind (Consumer Contracts Regulations 2013).</span> In accordance with the Consumer Contracts Regulations 2013, you have a legal right to change your mind within 14 days from the date you enter into the agreement with us and receive a refund. However, if you have already started to use the Services within the 14-day period referred to above, you will not be able to cancel your agreement with us and receive a refund.</li>
                  <li><span className={classes.bold}>If you want to end our agreement with you for another reason.</span> You can end your agreement with us if the circumstances set out at clauses 4.1 or 4.2 do not apply by giving us 1 month’s notice.</li>
                  <li><span className={classes.bold}>How to tell us you want to end our agreement with you.</span> To end the contract with us, please let us know by doing one of the following:
                    <ol type="a">
                      <li>Phone or email. Email us at <a href="mailto:hello@ydentity.com">hello@ydentity.com</a>. Please provide your name, home address, and where available, your phone number and email address.</li>
                      <li>By post. Write to us at our registered address, including details of what you bought, when you ordered or received it and your name and address.</li>
                    </ol>
                  </li>
                </ol>
              </li>

              <li><span className={classes.bold}>Our rights to end our agreement with you.  </span>
                <ol>
                  <li><span className={classes.bold}>We may end our agreement with you if you break it.</span> We may end our agreement with you at any time by writing to you if:
                    <ol type="a">
                      <li>you do not make any payment to us when it is due, and you still do not make payment within 7 days of us reminding you that payment is due;</li>
                      <li>you do not, within a reasonable time of us asking for it, provide us with information that is necessary for us to provide the Services.</li>
                    </ol>
                  </li>

                  <li><span className={classes.bold}>You must compensate us if you break the agreement with us.</span> If we end the contract in the situations set out in clause 5.1 we will refund any money you have paid in advance for Services we have not provided but we may deduct or charge you reasonable compensation for the net costs we will incur as a result of your breaking agreement with us.</li>
                </ol>
              </li>

              <li><span className={classes.bold}>Your obligations as a user of the Services.</span> You agree with us as follows:
                <ol>
                  <li>To keep your account details and password or any other piece of information as part of our security procedures, confidential at all times. You must not disclose it to any third party. We have the right to disable any user identification code or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use. If you know or suspect that anyone other than you know your user identification code or password, you must promptly notify us at: <a href="hello@ydentity.com">hello@ydentity.com</a>.</li>
                  <li>That we can monitor your use of the Services so as to ensure that they are being used in accordance with this agreement and our data protection and privacy policy.</li>

                  <li>That you will not use the Services:
                    <ol type="a">
                      <li>in such a way which is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing, racially or ethnically offensive;</li>
                      <li>in such a way that facilitates illegal activity;</li>
                      <li>to depict sexually explicit images;</li>
                      <li>promote unlawful violence;</li>
                      <li>to discriminate, based on race, gender, colour, religious belief, sexual orientation, disability; </li>
                      <li>for any other illegal purpose or to cause damage or injury to any person or property;</li>
                      <li>Attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, reverse engineer, or distribute all or any portion of the Services in any form or media or by any means; </li>
                      <li>Attempt to de-compile, reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the software which forms part of the Services; </li>
                      <li>Create or attempt to create software or documentation that is the same or similar to the Services;  </li>
                      <li>Access all or any part of the Services in order to build a product or service which competes with the Services; </li>
                      <li>License, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Services available to any third party;</li>
                      <li>Attempt to obtain, or assist third parties in obtaining, access to the Services other than as provided under this clause;</li>
                      <li>Not to misuse the Services by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful;</li>
                      <li>Not to gain or attempt to gain unauthorised access to the Services, our servers or any server or database connected to the Services. You must not attack the Services via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act 1990. We may report any such breach to the relevant law enforcement authorities, and we will co-operate with those authorities by disclosing your identity to them;</li>
                      <li>Comply fully with all applicable local, national or international laws and/or regulations; and</li>
                      <li>Not to use the Services in any way or for any purpose that is unlawful or fraudulent.</li>
                    </ol>

                    <p><span className={classes.bold}>Please note:</span> If we discover that you have used the Services for any of the purposes outlined above, we may immediately suspend or disable your access to the Services in addition to any other action we may take against you.</p>
                  </li>
                </ol>
              </li>

              <li><span className={classes.bold}>Availability of the Services.</span> We do not guarantee that the use of the Services will be uninterrupted or error free.  We may suspend or restrict the availability of all or part of the Services for business and operational reasons.  In addition, your use of the Services may be disabled or interrupted due to events beyond our control. By way of examples: utility failure, acts of god, strikes, terrorist attacks, epidemic or pandemic.</li>
              <li><span className={classes.bold}>Changes to the Services.</span> We reserve the right to make changes to the Services. If we wish to do so we will notify you in writing in advance. If we need to make changes to the Services to make technical adjustments or improvements; or to reflect changes in relevant laws and regulatory requirements, this will not entitle you to terminate your agreement with us.</li>
              <li><span className={classes.bold}>How you may use material available as part of the Services.</span> We are the owner or licensee of all intellectual property rights in and to the Services. This includes but is not limited to  logos, branding, source code, documentation and reports.  You may print off one copy, and may download extracts, of any page(s) created as a result of your use of the Services for your own personal use.</li>
              <li><span className={classes.bold}>Reliance on information provided as part of the Services.</span> To the fullest extent permitted by law, we make no representation, warranty or guarantee that your use of the Services will meet your requirements, that it will not infringe the rights of third parties or that it will be compatible with all software and hardware, or that it will be secure.</li>
              <li><span className={classes.bold}>How  to  tell  us if  there  is  a  problem  with  the Services.</span> If you have any questions or complaints about the product, please contact us. You can write to us at <a href="mailto:hello@ydentity.com">hello@ydentity.com</a>.</li>

              <li><span className={classes.bold}>Our liability to you. What we are not responsible for.</span>
                <ol>
                  <li><span className={classes.bold}>Third  party services. </span> Our Services use and interact with third party applications and in particular, a portal operated by Experian.  The use of the portal is subject to Experian’s term and conditions which can be found at <a href="https://id.ydentity.com/terms-and-conditions">https://id.ydentity.com/terms-and-conditions</a> and their data protection and privacy policy which can be found at <a href="https://id.ydentity.com/privacy-policy">https://id.ydentity.com/privacy-policy</a>.   We will not be responsible to you if you use any third-party services other than in accordance with the third party’s terms and conditions of use.</li>
                  <li><span className={classes.bold}>Results from the use of the Services.</span></li>
                </ol>
              </li>

              <li><span className={classes.bold}>Our  liability  to  you.    What  we  are  responsible for  and  the  limits of  our liability.</span>
                <ol>
                  <li>
                    <span className={classes.bold}>We are responsible to you for foreseeable loss and damage caused by   us.</span> If we fail to comply with these terms and conditions, we are responsible for loss or damage you suffer that is a foreseeable result of our breaking this contract or our failing to use reasonable care and skill, but we are not responsible for any loss or damage that is not foreseeable. Loss or damage is foreseeable if either it is obvious that it will happen or if, at the time the contract was made, both we and you knew it might happen.
                    <p className={classes.bold}>Please note: The limit of our liability to you is the amount you have paid for Services during the term of our agreement with you. </p>
                  </li>
                  <li><span className={classes.bold}>We do not exclude or limit in any way our liability to you where it would beunlawful to do so.</span> This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors; for fraud or fraudulent misrepresentation; for breach of your legal rights, including the right to receive Services which are: as described and match information we provided to you on our website; and for defective Services.</li>
                </ol>
              </li>

              <li><span className={classes.bold}>Viruses, Malware and Security.</span> We will exercise all reasonable skill and care to ensure that the Services are secure and free from viruses and other malware. You are responsible for protecting and configuring your information technology, computer programmes to access and use the Services.   You should use your own virus protection and security software.</li>

              <li><span className={classes.bold}>Other important terms</span>
                <ol>
                  <li><span className={classes.bold}>We may transfer this agreement to someone else.</span> We may transfer our rights and obligations under these terms to another organisation. </li>
                  <li><span className={classes.bold}>You need our consent to transfer your rights to someone else. </span> You may only transfer your rights or your obligations under these terms to another person if we agree to this in writing.</li>
                  <li><span className={classes.bold}>Nobody else has any rights under  this agreement. </span> This contract is between you and us. No other person shall have any rights to enforce any of its terms.</li>
                  <li><span className={classes.bold}>If a court finds part of this contract illegal, the rest will continue in force.</span> Each of the paragraphs of these terms operates separately. If any court or relevant authority decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.</li>
                  <li><span className={classes.bold}>Even if we delay in enforcing this contract, we can still enforce it later.</span> If we do not insist immediately that you do anything you are required to do under these terms, or if we delay in taking steps against you in respect of your breaking this agreement, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date. For example, if you miss a payment and we do not chase you but we continue to provide the products, we can still require you to make the payment at a later date.</li>
                  <li><span className={classes.bold}>The laws that apply to this agreement.</span> This agreement is governed by the law of England and Wales.  We both agree that the courts of England and Wales will have exclusive jurisdiction, regardless of where you use or access the Services.</li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default compose(
  withRouter,
  withFirebase
)(TermsAndConditionsPage);
