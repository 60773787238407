import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconWelcome from "../../../images/icon-welcome.svg";
import UpsellBG from "../../../images/upsell-bg.jpg";
import UpsellLI from "../../../images/upsell-li.svg";
import { ReactSVG } from "react-svg";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import * as ROUTES from "../../../constants/routes";
import UpsellPrice from "../../UpsellPrice";
import HowItWorks from "../../../images/how-it-works.png";

const useStyles = makeStyles(theme => ({
  contentContainer: {
    paddingTop: theme.spacing(3)
  },

  divider: {
    height: "1px",
    backgroundColor: "#e9e9e9"
  },

  noBreachesPaper: {
    padding: theme.spacing(4, 4),
    position: 'relative',
    overflow: 'hidden',
    margin: '24px 8px'
  },

  noBreachesSideRect: {
    position: 'absolute',
    backgroundColor: '#05c3be',
    width: '15px',
    left: '0px',
    bottom: '0px',
    top: '0px'
  },

  summaryText: {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "rgba(35, 38, 43, 0.65)",
    marginTop: '5px',
    marginBottom: '5px'
  },
  summaryTextBold: {
    fontWeight: 500
  },
  trophy: {
    width: '74px',
    height: '74px',
    objectFit: 'contain'
  },
  goodNews: {
    width: '100%'
  },

  upsell: {
    backgroundImage: "url(" + UpsellBG + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "175%",
    backgroundPosition: "90% 10%",
    background: "#05c3be",
    width: "100%",
    marginTop: '24px'
  },
  upsellh2: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellh1: {
    fontSize: "35px",
    fontWeight: 500
  },
  upsellGrid: {
    padding: theme.spacing(6, 4),
    position: 'relative'
  },
  upsellPrice: {
    fontFamily: "Lato",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellLargeText: {
    fontSize: "64px"
  },
  upsellMonthlyText: {
    fontSize: "24px"
  },
  upsellFeaturesDivider: {
    height: "1px",
    opacity: 0.3,
    backgroundColor: "#ffffff"
  },
  upsellFeaturesTitle: {
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellFeaturesList: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.88,
    letterSpacing: "normal",
    color: "#ffffff",
    listStyleImage: "url(" + UpsellLI + ")"
  },

  upsellFeaturesUl: {
    paddingLeft: '20px'
  },

  upsellButton: {
    height: "50px",
    borderRadius: "3px",
    backgroundColor: "#0b8af0",
    margin: theme.spacing(3, 0, 2)
  },

  vr: {
    position: 'absolute',
    borderRight: '1px solid #9adade',
    left: '50%',
    bottom: '100px',
    top: '100px'
  },

  vrWrapper: {
    position: 'relative'
  },

  welcomeText: {
    fontFamily: "Lato",
    fontSize: "35px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  title: {
    background: 'linear-gradient(to bottom, #1693b9, #03337d)',
    width: '100%',
    color: 'white',
    height: 'fit-content',
    textAlign: 'center',
    fontFamily: 'Lato',
    fontWeight: 'bold',
    padding: '10px 0px',
  },

  wrapper: {
    height: 'fit-content'
  },

  imageWrapper: {
    width: '100%'
  },

  image: {
    width: '100%'
  },

  blurb: {
    padding: '10px'
  }
}));

const HowYourReportWorks = (props) => {
  const classes = useStyles();

  return <Grid item container md={3} className={classes.wrapper}>
          <div className={classes.title}>
            <Typography variant="h5" component="h3">How your report works</Typography>
          </div>

          <div className={classes.imageWrapper}>
            <img src={HowItWorks} className={classes.image} />
          </div>

          <div className={classes.blurb}>
            If a match to your personal information is found, your risk of identity theft is increased. Being in the know about your compromised personal information can help you mitigate risk by taking proactive steps to protect yourself.
            <br /><br />
            We have highlighted further information and actions needed, if your personal data is at risk
          </div>
  </Grid>
};

export default HowYourReportWorks;
