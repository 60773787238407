import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { } from "react";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";

const styles = theme => ({
  upsellPrice: {
    fontFamily: "Lato",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff"
  },
  upsellLargeText: {
    fontSize: "64px"
  },
  upsellMonthlyText: {
    fontSize: "24px"
  },
});

class UpsellPrice extends React.Component {
  classes = {};
  currency = '£';
  integers = '9';
  fractions = '99';

  render() {
    this.classes = this.props.classes;

    return <Grid item>
      <Typography
        variant="h3"
        className={this.classes.upsellPrice}
      >
        {this.currency}
        <span
          className={[
            this.classes.upsellPrice,
            this.classes.upsellLargeText
          ].join(" ")}
        >
          {this.integers}
        </span>
                        .{this.fractions}
        <span
          className={[
            this.classes.upsellPrice,
            this.classes.upsellMonthlyText
          ].join(" ")}
        >
                        </span>
      </Typography>
    </Grid>

  }
}

export default compose(
  withFirebase,
  withStyles(styles)
)(UpsellPrice);
