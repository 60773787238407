import { makeStyles } from "@material-ui/core/styles";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { compose } from "recompose";
import { withFirebase } from "../Firebase";
import { AuthUserContext, withAuthorization } from "../Session";
import Breaches from "./Breaches";
import NoBreaches from "./NoBreaches";

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#fafafa",
    flexGrow: 1,
    position: 'relative'
  },
  contentContainer: {
    paddingTop: theme.spacing(3)
  },

  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: "flex"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: "80%",
    height: "75px",
    borderRadius: "8px",
    padding: "20px",
    objectFit: "scale-down",
    background: "#e0e0e0"
  },
  divider: {
    height: "1px",
    backgroundColor: "#e9e9e9"
  },

  loaderWrapper: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    height: '100%',
    minHeight: '100px'
  }
}));

function AcurisHome(props) {
  let [loaded, setLoaded] = useState(false);
  let [breaches, setBreaches] = useState([]);
  let [acurisBreaches, setAcurisBreaches] = useState(0);

  const userContext = useContext(AuthUserContext);
  const classes = useStyles();

  useInterval(
    () => {
      if (loaded) {
        return;
      }

      props.firebase.getUser(userContext.uid).then(user => {
        if(!user.acurisBreaches) {
          props.firebase.upgradeAccount().catch(err => console.log('err', err));
          return;
        }
        
        if (anyAcurisBreaches(user.acurisBreaches))
          setAcurisBreaches(user.acurisBreaches);
        setLoaded(true);
      });
    },
    loaded ? null : 1000
  );

  const anyAcurisBreaches = (breaches) => {
    if(breaches === undefined)
      return false;
    
    const breachTypeCounts = Object.keys(breaches).map(key => breaches[key].length);

    return breachTypeCounts.filter(b => b > 0).length > 0;
  }

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className={classes.root}>
          {loaded ? (
            <div>
              {acurisBreaches ?
                <Breaches breaches={breaches} acurisBreaches={acurisBreaches} user={authUser} firebase={props.firebase} history={props.history} />
                : <NoBreaches user={authUser} acurisBreaches={acurisBreaches} firebase={props.firebase} history={props.history} />
              }
            </div>
          ) : (
            <div className={classes.loaderWrapper}>
              <ReactLoading type="spin" color="#000" />
            </div>
          )}
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase
)(AcurisHome);
