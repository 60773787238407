import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  // *** Auth API ***

  async getUser(uid) {
    var dbUser = await this.user(uid)
      .get()
      .then(snapshot => {
        console.log('Get user - ' + snapshot.data());
        return snapshot.data();
      });

    return {
      ...dbUser
    };
  }

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email, {
    url: 'https://app.ydentity.com',
    handleCodeInApp: false
  });

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_EMAIL_REDIRECT
    });

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        const idToken = authUser.getIdToken().then(token => {
          this.user(authUser.uid)
            .get()
            .then(snapshot => {
              const dbUser = snapshot.data();

              if (dbUser === undefined) {
                return;
              }

              // merge auth and db user
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                subscription: authUser.subscription,
                idToken: token,
                ...dbUser
              };

              next(authUser);
            });
        });

      } else {
        fallback();
      }
    });

  // *** User API ***

  user = uid => this.db.doc(`users/${uid}`);

  users = () => this.db.collection("users");

  profilePicture = () => this.storage.ref("images/profile-pictures");

  subscribeToPremium = (data) => app.functions().httpsCallable('subscribeToPremium')(data);
  upgradeAccount = () => app.functions().httpsCallable('upgradeAccount')();
}

export default Firebase;
