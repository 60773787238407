import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import Logo from "../../images/logo.svg";
import { AuthUserContext } from "../Session";
import SignOutButton from "../SignOut";

const useStyles = makeStyles(theme => ({
  root: {
    flexShrink: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    marginLeft: "30px"
  },
  appBar: {
    backgroundImage: "linear-gradient(to bottom, #1693b9, #03337d)",
    minHeight: 100
  },
  signOut: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "right",
    color: "#ffffff",
    textTransform: "none",
    backgroundColor: 'inherit',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
}));

function Navigation(props) {
  const classes = useStyles();

  return (
    <AuthUserContext.Consumer>
      {authUser => (
        <div className={classes.root}>
          <AppBar position="static" className={classes.appBar}>
            <Toolbar style={{ minHeight: 100 }}>
              <Typography
                variant="h6"
                className={classes.title}
                component={Link}
                to={ROUTES.HOME}
              >
                <img src={Logo} height="30" alt="Ydentity Logo" />
              </Typography>
              {authUser ? (
                <div>
                  <div style={{display: 'inline-block'}}>
                    {authUser.subscription && authUser.subscription.active ?
                      <div style={{display: 'inline-block'}}>
                      </div> :
                      <div style={{display: 'inline-block'}}>
                        <Button
                          component={Link}
                          to={ROUTES.SUBSCRIBE_TO_PREMIUM}
                          color="inherit"
                          className={classes.signOut}>
                            Unlock Full Report
                          </Button>
                      </div>}
                      </div>
                  <Button
                    component={Link}
                    to={ROUTES.ACCOUNT}
                    color="inherit"
                    className={classes.signOut}
                  >
                    My Account
                  </Button>
                  <SignOutButton />
                </div>
              ) : (
                <div>
                  <Button
                    component={Link}
                    to={ROUTES.LOGIN}
                    color="inherit"
                    className={classes.signOut}
                  >
                    Login
                  </Button>
                </div>
              )}
            </Toolbar>
          </AppBar>
        </div>
      )}
    </AuthUserContext.Consumer>
  );
}

export default Navigation;
