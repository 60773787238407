import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import IconBreaches from "../../../images/icon-breaches.svg";
import IconWelcome from "../../../images/icon-welcome.svg";
import dompurify from "dompurify";
import UpsellBG from "../../../images/upsell-bg.jpg";
import UpsellLI from "../../../images/upsell-li.svg";
import * as ROUTES from "../../../constants/routes";
import UpsellPrice from "../../UpsellPrice";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#fafafa",
    flexGrow: 1,
    position: 'relative'
  },
  contentContainer: {
    paddingTop: theme.spacing(3)
  },

  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: "flex"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: "80%",
    height: "75px",
    borderRadius: "8px",
    padding: "20px",
    objectFit: "scale-down",
    background: "#e0e0e0"
  },
  divider: {
    height: "1px",
    backgroundColor: "#e9e9e9"
  },

  breachesBigText: {
    fontSize: "54px",
    fontWeight: 500
  },

  breachesPaper: {
    padding: theme.spacing(3, 2)
  },
  breachesText: {
    fontFamily: "Lato",
    fontSize: "20px",
    display: "block",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  summaryText: {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "rgba(35, 38, 43, 0.65)"
  },
  summaryTextBold: {
    fontWeight: 500
  },
  breachDateText: {
    fontWeight: 300
  },
  breachDescriptionText: {
    opacity: 0.65,
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.69,
    letterSpacing: "normal",
    color: "#23262b"
  },

  upsell: {
    backgroundImage: "url(" + UpsellBG + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    background: "#05c3be",
    width: "100%"
  },
  upsellh2: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellh1: {
    fontSize: "35px",
    fontWeight: 500
  },
  upsellGrid: {
    padding: theme.spacing(4, 4)
  },
  upsellPrice: {
    fontFamily: "Lato",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff"
  },
  upsellLargeText: {
    fontSize: "64px"
  },
  upsellMonthlyText: {
    fontSize: "24px"
  },
  upsellFeaturesDivider: {
    height: "1px",
    opacity: 0.3,
    backgroundColor: "#ffffff"
  },
  upsellFeaturesTitle: {
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellFeaturesList: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.88,
    letterSpacing: "normal",
    color: "#ffffff",
    listStyleImage: "url(" + UpsellLI + ")"
  },

  upsellButton: {
    height: "50px",
    borderRadius: "3px",
    backgroundColor: "#0b8af0",
    margin: theme.spacing(3, 0, 2)
  },

  welcomeText: {
    fontFamily: "Lato",
    fontSize: "35px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  titleText: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },
}));

const Breaches = (props) => {
  const classes = useStyles();

  const sanitizer = dompurify.sanitize;

  return <div>
              <Container>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  className={classes.contentContainer}
                >
                  <Grid item container direction="column" spacing={3} md={9}>
                    <Grid item container spacing={2}>
                      <Grid item container md={12} spacing={2}>
                        <Grid item>
                          <img src={IconWelcome} className={classes.ydLogo} />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="h4"
                            component="h1"
                            className={classes.welcomeText}
                          >
                            Welcome {props.user.firstName} {props.user.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item md={12}>
                        <div className={classes.divider}></div>
                      </Grid>

              <Paper className={classes.noBreachesPaper}>
                <div className={classes.noBreachesSideRect}></div>
                <Grid item container spacing={2} direction="row" alignItems="center" justify="center">
                  {props.acurisBreaches > 0 ?
                    <div>Bad news, your email has been found in the Acuris breach database {props.acurisBreaches} times. <a href="/acuris-sign-up">Sign up now</a> to see the results and be alerted if any more are ever found.</div>
                    : <div>Good news, your email was not found in the Acuris breach database. <a href="/acuris-sign-up">Sign up now</a> to be alerted if results are ever found.</div>
                  }
                </Grid>
              </Paper>

                      <Grid item md={12}>
                        <Paper className={classes.breachesPaper}>
                          <Grid item container spacing={2} direction="row">
                            <Grid item md={2}>
                              <Typography
                                variant="h2"
                                className={classes.breachesBigText}
                              >
                                {props.breaches.length}
                                <span className={classes.breachesText}>
                                  Breaches
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item md={10}>
                              <p className={classes.titleText}>Summary</p>
                              <p className={classes.summaryText}>
                                The email address associated with your account (
                                <span className={classes.summaryTextBold}>
                                  {props.user.email}
                                </span>
                                ) has been breached {props.breaches.length} times.
                              </p>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item container md={12} spacing={2}>
                        <Grid item>
                          <img src={IconBreaches} className={classes.ydLogo} />
                        </Grid>
                        <Grid item>
                          <Typography variant="h4" component="h1">
                            Breaches
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item md={12}>
                        <div className={classes.divider}></div>
                      </Grid>
                      <Grid item container md={12} spacing={2}>
                        {props.breaches.map(breach => (
                          <Grid item key={breach.Title} md={12}>
                            <CardActionArea component="a" href="#">
                              <Card className={classes.card}>
                                <div className={classes.cardDetails}>
                                  <CardContent>
                                    <Grid
                                      container
                                      direction="column"
                                      spacing={2}
                                    >
                                      <Grid
                                        item
                                        container
                                        direction="row"
                                        spacing={3}
                                        md={12}
                                      >
                                        <Grid item md={3}>
                                          <CardMedia
                                            component="img"
                                            className={classes.cardMedia}
                                            image={breach.LogoPath}
                                            title="Image title"
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          container
                                          direction="column"
                                          md={9}
                                        >
                                          <Grid item>
                                            <Typography
                                              component="h2"
                                              variant="h4"
                                              className={classes.titleText}
                                            >
                                              {breach.Title}
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Grid
                                              container
                                              direction="row"
                                              spacing={2}
                                            >
                                              <Grid item>
                                                <Typography
                                                  component="h2"
                                                  variant="h4"
                                                  className={
                                                    classes.summaryText
                                                  }
                                                >
                                                  <span
                                                    className={[
                                                      classes.summaryText,
                                                      classes.summaryTextBold
                                                    ].join(" ")}
                                                  >
                                                    Breach date:
                                                  </span>{" "}
                                                  <Moment format="D MMM YYYY">
                                                    {breach.BreachDate}
                                                  </Moment>
                                                </Typography>
                                              </Grid>
                                              <Grid item>
                                                <Typography
                                                  component="h2"
                                                  variant="h4"
                                                  className={
                                                    classes.summaryText
                                                  }
                                                >
                                                  <span
                                                    className={[
                                                      classes.summaryText,
                                                      classes.summaryTextBold
                                                    ].join(" ")}
                                                  >
                                                    Breach size:
                                                  </span>{" "}
                                                  <NumberFormat
                                                    value={breach.PwnCount}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                  />
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item md={12}>
                                        <div className={classes.divider}></div>
                                      </Grid>
                                      <Grid item md={12}>
                                        <Typography
                                          variant="body1"
                                          className={
                                            classes.breachDescriptionText
                                          }
                                          dangerouslySetInnerHTML={{
                                            __html: sanitizer(
                                              breach.Description
                                            )
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </div>
                              </Card>
                            </CardActionArea>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container md={3}>
                    <Grid item>
                      <Card className={classes.upsell}>
                        <Grid
                          container
                          className={classes.upsellGrid}
                          spacing={3}
                        >
                          <Grid container item direction="column" spacing={3}>
                            <Grid item>
                              <Typography
                                variant="h2"
                                className={[
                                  classes.upsellh2,
                                  classes.upsellh1
                                ].join(" ")}
                              >
                                Are you concerned about your wider data?
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="h3"
                                className={classes.upsellh2}
                              >
                                Don’t worry, we’ve got you covered on our
                                Premium Plan.
                              </Typography>
                            </Grid>

                            <UpsellPrice></UpsellPrice>
                          </Grid>
                          <Grid item container spacing={3}>
                            <Grid item>
                              <Typography
                                variant="h4"
                                className={classes.upsellFeaturesTitle}
                              >
                                Features
                              </Typography>
                            </Grid>
                            <Grid item style={{ width: "100%" }}>
                              <div
                                className={classes.upsellFeaturesDivider}
                              ></div>
                            </Grid>
                            <Grid item className={classes.upsellFeaturesList}>
                              <ul>
                                <li>Email addresses</li>
                                <li>Telephone numbers</li>
                                <li>Credit/Debit card numbers</li>
                                <li>Bank account numbers</li>
                                <li>Driver's license numbers</li>
                                <li>Passport numbers</li>
                                <li>National identification numbers</li>
                                <li>Retail credit cards</li>
                                <li>
                                  International Bank Account Numbers (IBAN)
                                </li>
                              </ul>
                            </Grid>

                            <Grid item style={{ width: "100%" }}>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.upsellButton}
                                onClick={() => {
                                  props.history.push(ROUTES.SUBSCRIBE_TO_PREMIUM);
                                }}
                              >
                                Upgrade now
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </div>

};

export default Breaches;
