import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconWelcome from "../../../images/icon-welcome.svg";
import UpsellBG from "../../../images/upsell-bg.jpg";
import UpsellLI from "../../../images/upsell-li.svg";
import { ReactSVG } from "react-svg";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import * as ROUTES from "../../../constants/routes";
import UpsellPrice from "../../UpsellPrice";

const useStyles = makeStyles(theme => ({
  contentContainer: {
    paddingTop: theme.spacing(3)
  },

  divider: {
    height: "1px",
    backgroundColor: "#e9e9e9"
  },

  noBreachesPaper: {
    padding: theme.spacing(4, 4),
    position: 'relative',
    overflow: 'hidden',
    margin: '24px 8px'
  },

  noBreachesSideRect: {
    position: 'absolute',
    backgroundColor: '#05c3be',
    width: '15px',
    left: '0px',
    bottom: '0px',
    top: '0px'
  },

  summaryText: {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "rgba(35, 38, 43, 0.65)",
    marginTop: '5px',
    marginBottom: '5px'
  },
  summaryTextBold: {
    fontWeight: 500
  },
  trophy: {
    width: '74px',
    height: '74px',
    objectFit: 'contain'
  },
  goodNews: {
    width: '100%'
  },

  upsell: {
    backgroundImage: "url(" + UpsellBG + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "175%",
    backgroundPosition: "90% 10%",
    background: "#05c3be",
    width: "100%",
    marginTop: '24px'
  },
  upsellh2: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellh1: {
    fontSize: "35px",
    fontWeight: 500
  },
  upsellGrid: {
    padding: theme.spacing(6, 4),
    position: 'relative'
  },
  upsellPrice: {
    fontFamily: "Lato",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellLargeText: {
    fontSize: "64px"
  },
  upsellMonthlyText: {
    fontSize: "24px"
  },
  upsellFeaturesDivider: {
    height: "1px",
    opacity: 0.3,
    backgroundColor: "#ffffff"
  },
  upsellFeaturesTitle: {
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellFeaturesList: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.88,
    letterSpacing: "normal",
    color: "#ffffff",
    listStyleImage: "url(" + UpsellLI + ")"
  },

  upsellFeaturesUl: {
    paddingLeft: '20px'
  },

  upsellButton: {
    height: "50px",
    borderRadius: "3px",
    backgroundColor: "#0b8af0",
    margin: theme.spacing(3, 0, 2)
  },

  vr: {
    position: 'absolute',
    borderRight: '1px solid #9adade',
    left: '50%',
    bottom: '100px',
    top: '100px'
  },

  vrWrapper: {
    position: 'relative'
  },

  welcomeText: {
    fontFamily: "Lato",
    fontSize: "35px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },
}));

const NoBreaches = (props) => {
  const classes = useStyles();

  return <div>
              <Container>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  className={classes.contentContainer}
                  justify="center"
                >
                  <Grid item container direction="column" spacing={3} md={9}>
                    <Grid item container spacing={2} direction="column">
                      <Grid item container md={12} spacing={2}>
                        <Grid item>
                          <img src={IconWelcome} className={classes.ydLogo} />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="h4"
                            component="h1"
                            className={classes.welcomeText}
                          >
                            Welcome {props.user.firstName} {props.user.lastName}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid item md={12}>
                        <div className={classes.divider}></div>
                      </Grid>

              <Paper className={classes.noBreachesPaper}>
                <div className={classes.noBreachesSideRect}></div>
                <Grid item container spacing={2} direction="row" alignItems="center" justify="center">
                  {props.acurisBreaches > 0 ?
                    <div>Bad news, your email has been found in the Acuris breach database {props.acurisBreaches} times. <a href="/acuris-sign-up">Sign up now</a> to see the results and be alerted if any more are ever found.</div>
                    : <div>Good news, your email was not found in the Acuris breach database. <a href="/acuris-sign-up">Sign up now</a> to be alerted if results are ever found.</div>
                  }
                </Grid>
              </Paper>
                      <Paper className={classes.noBreachesPaper}>
                        <div className={classes.noBreachesSideRect}></div>
                        <Grid item container spacing={2} direction="row" alignItems="center" justify="center">
                          <Grid item md={2} container justify="center" alignItems="center">
                            <ReactSVG src="/no-breaches-trophy.svg" className={classes.trophy} />
                          </Grid>
                          <Grid item md={10} container justify="center" alignItems="center">
                            <Typography variant="h5" className={classes.goodNews}>Good news!</Typography>
                            <p className={classes.summaryText}>
                              Congratulations! Your email address
                              (<span className={classes.summaryTextBold}>
                                {props.user.email}
                              </span>)
                              has not been involved in any breaches!
                            </p>
                          </Grid>
                        </Grid>
                      </Paper>

                  <Grid item container>
                    <Grid item>
                      <Card className={classes.upsell}>
                        <Grid
                          container
                          className={classes.upsellGrid}
                          spacing={10}
                          justify="center"
                        >
                          <Grid container item direction="column" spacing={3} md={6}>
                            <Grid item>
                              <Typography
                                variant="h2"
                                className={[
                                  classes.upsellh2,
                                  classes.upsellh1
                                ].join(" ")}
                              >
                                Are you concerned about your wider data?
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="h3"
                                className={classes.upsellh2}
                              >
                                Don’t worry, we’ve got you covered on our
                                Premium Plan.
                              </Typography>
                            </Grid>

                            <UpsellPrice></UpsellPrice>
                          </Grid>
                          <div className={classes.vr}></div>
                          <Grid item container spacing={0} md={5} className={classes.upsellRight}>
                            <Grid item>
                              <Typography
                                variant="h4"
                                className={classes.upsellFeaturesTitle}
                              >
                                Features
                              </Typography>
                            </Grid>
                            <Grid item style={{ width: "100%", marginTop: '15px' }}>
                              <div
                                className={classes.upsellFeaturesDivider}
                              ></div>
                            </Grid>
                            <Grid item className={classes.upsellFeaturesList}>
                              <ul className={classes.upsellFeaturesUl}>
                                <li>Email addresses</li>
                                <li>Telephone numbers</li>
                                <li>Credit/Debit card numbers</li>
                                <li>Bank account numbers</li>
                                <li>Driver's license numbers</li>
                                <li>Passport numbers</li>
                                <li>National identification numbers</li>
                                <li>Retail credit cards</li>
                                <li>
                                  International Bank Account Numbers (IBAN)
                                </li>
                              </ul>
                            </Grid>

                            <Grid item style={{ width: "100%" }}>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.upsellButton}
                                onClick={() => {
                                  props.history.push(ROUTES.SUBSCRIBE_TO_PREMIUM);
                                }}
                              >
                                Upgrade now
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </div>

};

export default NoBreaches;
