import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import UpsellBG from "../../images/upsell-bg.jpg";
import UpsellLI from "../../images/upsell-li.svg";
import * as ROUTES from "../../constants/routes";
import UpsellPrice from "../UpsellPrice";
import { compose } from "recompose";
import { AuthUserContext, withAuthorization } from "../Session";
import { withFirebase } from "../Firebase";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#fafafa",
    flexGrow: 1,
    position: 'relative'
  },
  contentContainer: {
    paddingTop: theme.spacing(3)
  },

  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: "flex"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: "80%",
    height: "75px",
    borderRadius: "8px",
    padding: "20px",
    objectFit: "scale-down",
    background: "#e0e0e0"
  },
  divider: {
    height: "1px",
    backgroundColor: "#e9e9e9"
  },

  breachesBigText: {
    fontSize: "54px",
    fontWeight: 500
  },

  breachesPaper: {
    padding: theme.spacing(3, 2)
  },
  breachesText: {
    fontFamily: "Lato",
    fontSize: "20px",
    display: "block",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  summaryText: {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "rgba(35, 38, 43, 0.65)"
  },
  summaryTextBold: {
    fontWeight: 500
  },
  breachDateText: {
    fontWeight: 300
  },
  breachDescriptionText: {
    opacity: 0.65,
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.69,
    letterSpacing: "normal",
    color: "#23262b"
  },

  upsell: {
    backgroundImage: "url(" + UpsellBG + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    background: "#05c3be",
    width: "100%"
  },
  upsellh2: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellh1: {
    fontSize: "35px",
    fontWeight: 500
  },
  upsellGrid: {
    padding: theme.spacing(4, 4)
  },
  upsellPrice: {
    fontFamily: "Lato",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff"
  },
  upsellLargeText: {
    fontSize: "64px"
  },
  upsellMonthlyText: {
    fontSize: "24px"
  },
  upsellFeaturesDivider: {
    height: "1px",
    opacity: 0.3,
    backgroundColor: "#ffffff"
  },
  upsellFeaturesTitle: {
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellFeaturesList: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.88,
    letterSpacing: "normal",
    color: "#ffffff",
    listStyleImage: "url(" + UpsellLI + ")"
  },

  upsellButton: {
    height: "50px",
    borderRadius: "3px",
    backgroundColor: "#0b8af0",
    margin: theme.spacing(3, 0, 2)
  },

  welcomeText: {
    fontFamily: "Lato",
    fontSize: "35px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  titleText: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },
}));
function Account(props) {
  const classes = useStyles();

  return (
    <AuthUserContext.Consumer>
      {() => (
        <Grid item container md={3}>
          <Grid item>
            <Card className={classes.upsell}>
              <Grid
                container
                className={classes.upsellGrid}
                spacing={3}
              >
                <Grid container item direction="column" spacing={3}>
                  <Grid item>
                    <Typography
                      variant="h2"
                      className={[
                        classes.upsellh2,
                        classes.upsellh1
                      ].join(" ")}
                    >
                      Unlock your full report now
                    </Typography>
                  </Grid>

                  <UpsellPrice></UpsellPrice>
                </Grid>
                <Grid item container spacing={3}>
                  <Grid item>
                    <Typography
                      variant="h4"
                      className={classes.upsellFeaturesTitle}
                    >
                      Features
                    </Typography>
                  </Grid>
                  <Grid item style={{ width: "100%" }}>
                    <div
                      className={classes.upsellFeaturesDivider}
                    ></div>
                  </Grid>
                  <Grid item className={classes.upsellFeaturesList}>
                    <ul>
                      <li>Email addresses</li>
                      <li>Telephone numbers</li>
                      <li>Credit/Debit card numbers</li>
                      <li>Bank account numbers</li>
                      <li>Driver's license numbers</li>
                      <li>Passport numbers</li>
                      <li>National identification numbers</li>
                      <li>Retail credit cards</li>
                      <li>
                        International Bank Account Numbers (IBAN)
                      </li>
                    </ul>
                  </Grid>

                  <Grid item style={{ width: "100%" }}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.upsellButton}
                      onClick={() => {
                        props.history.push(ROUTES.SUBSCRIBE_TO_PREMIUM);
                      }}
                    >
                      Upgrade now
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      )}
    </AuthUserContext.Consumer>
  );
}

const condition = authUser => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase
)(Account);
