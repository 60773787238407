import React from "react";
import { Route, Router, Redirect } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import AccountPage from "../Account";
import DebugPage from "../Debug";
import HomePage from "../Home";
import SignInPage from "../Login";
import Navigation from "../Navigation";
import PasswordForgetPage from "../PasswordForget";
import { withAuthentication } from "../Session";
import SignUpPage from "../SignUp";
import SubscribeToPremiumPage from "../SubscribeToPremium";
import AcurisHomePage from "../AcurisHome";
import TermsAndConditionsPage from "../TermsAndConditions";
import history from "./history";
import { makeStyles } from "@material-ui/core/styles";
import { ToastProvider } from 'react-toast-notifications';
import DiscountPage from "../Discount";

const useStyles = makeStyles(theme => ({
  root: {
    'height': '100%'
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%'
  }
}));

const App = () => {
  const classes = useStyles();

  const ACURIS_HOME_PAGE = (props) => {
    return (
      <div className={classes.wrapper}>
        <Navigation location={props.location} history={props.history} />
        <AcurisHomePage />
      </div>
    )
  }

  const DEBUG_PAGE = (props) => (
    <div className={classes.wrapper}>
      <Navigation location={props.location} history={props.history} />
      <DebugPage />
    </div>
  );

  const ACCOUNT_PAGE = (props) => (
    <div className={classes.wrapper}>
      <Navigation location={props.location} history={props.history} />
      <AccountPage />
    </div>
  );

  return <ToastProvider>
  <Router history={history}>
    <div className={classes.root}>
      <Route exact path={ROUTES.SIGN_UP} component={SignUpPage} />
      <Route exact path={ROUTES.LOGIN} component={SignInPage} />
      <Route exact path={ROUTES.DISCOUNT} component={DiscountPage} />
      <Route exact path={ROUTES.SUBSCRIBE_TO_PREMIUM} component={SubscribeToPremiumPage} />
      <Route exact path={ROUTES.ACCESS_PREMIUM} component={SubscribeToPremiumPage} />
      <Route exact path={ROUTES.TERMS_AND_CONDITIONS} component={TermsAndConditionsPage} />
      <Route exact path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
      <Route exact path={ROUTES.HOME} component={ACURIS_HOME_PAGE} />
      <Route exact path={ROUTES.DEBUG} component={DEBUG_PAGE} />
      <Route exact path={ROUTES.ACCOUNT} component={ACCOUNT_PAGE} />
    </div>
    <Route exact path="/" render={() => (
      <Redirect to={ROUTES.HOME} />
    )} />
  </Router>
</ToastProvider>
};

export default withAuthentication(App);
