import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import dompurify from "dompurify";
import React, { useContext, useEffect, useRef, useState } from "react";
import Moment from "react-moment";
import NumberFormat from "react-number-format";
import { compose } from "recompose";
import IconBreaches from "../../images/icon-breaches.svg";
import IconWelcome from "../../images/icon-welcome.svg";
import UpsellBG from "../../images/upsell-bg.jpg";
import UpsellLI from "../../images/upsell-li.svg";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import UpsellPrice from "../UpsellPrice";

const renderTableData = breaches => {
  return breaches.map(breach => {
    const {
      Name,
      Title,
      Domain,
      BreachDate,
      AddedDate,
      ModifiedDate,
      PwnCount,
      Description,
      LogoPath,
      DataClasses,
      IsVerified,
      IsFabricated,
      IsSensitive,
      IsRetired,
      IsSpamList
    } = breach; //destructuring

    return (
      <tr key={Name}>
        <td>{Name}</td>
        <td>{Title}</td>
        <td>{Domain}</td>
        <td>{BreachDate}</td>
        <td>{AddedDate}</td>
        <td>{ModifiedDate}</td>
        <td>{PwnCount}</td>
        <td>{Description}</td>
        <td>{LogoPath}</td>
        <td>{DataClasses}</td>
        <td>{IsVerified}</td>
        <td>{IsFabricated}</td>
        <td>{IsSensitive}</td>
        <td>{IsRetired}</td>
        <td>{IsSpamList}</td>
      </tr>
    );
  });
};

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const useStyles = makeStyles(theme => ({
  root: {
    // padding: theme.spacing(3, 2),
    backgroundColor: "#fafafa"
  },
  contentContainer: {
    paddingTop: theme.spacing(3)
  },

  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: "flex"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: "80%",
    height: "75px",
    borderRadius: "8px",
    padding: "20px",
    objectFit: "scale-down",
    background: "#e0e0e0"
  },
  divider: {
    height: "1px",
    backgroundColor: "#e9e9e9"
  },

  welcomeText: {
    fontFamily: "Lato",
    fontSize: "35px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  titleText: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  breachesBigText: {
    fontSize: "54px",
    fontWeight: 500
  },

  breachesPaper: {
    padding: theme.spacing(3, 2)
  },
  breachesText: {
    fontFamily: "Lato",
    fontSize: "20px",
    display: "block",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  summaryText: {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "rgba(35, 38, 43, 0.65)"
  },
  summaryTextBold: {
    fontWeight: 500
  },
  breachDateText: {
    fontWeight: 300
  },
  breachDescriptionText: {
    opacity: 0.65,
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.69,
    letterSpacing: "normal",
    color: "#23262b"
  },
  upsell: {
    backgroundImage: "url(" + UpsellBG + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    background: "#05c3be",
    width: "100%"
  },
  upsellh2: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellh1: {
    fontSize: "35px",
    fontWeight: 500
  },
  upsellGrid: {
    padding: theme.spacing(4, 4)
  },
  upsellPrice: {
    fontFamily: "Lato",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff"
  },
  upsellLargeText: {
    fontSize: "64px"
  },
  upsellMonthlyText: {
    fontSize: "24px"
  },
  upsellFeaturesDivider: {
    height: "1px",
    opacity: 0.3,
    backgroundColor: "#ffffff"
  },
  upsellFeaturesTitle: {
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellFeaturesList: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.88,
    letterSpacing: "normal",
    color: "#ffffff",
    listStyleImage: "url(" + UpsellLI + ")"
  },

  upsellButton: {
    height: "50px",
    borderRadius: "3px",
    backgroundColor: "#0b8af0",
    margin: theme.spacing(3, 0, 2)
  }
}));

function Home(props) {
  let [loaded, setLoaded] = useState(false);
  let [breaches, setBreaches] = useState([]);

  const userContext = useContext(AuthUserContext);
  const classes = useStyles();

  const sanitizer = dompurify.sanitize;

  useInterval(
    () => {
      if (loaded) {
        return;
      }

          setBreaches(authUser.breaches);
          setLoaded(true);
    },
    loaded ? null : 1000
  );

  const authUser = {
    "breaches": [
      {
        "AddedDate": "2019-03-25T08:01:09Z",
        "BreachDate": "2018-07-05",
        "DataClasses": [
          "Dates of birth",
          "Email addresses",
          "Genders",
          "Geographic locations",
          "Names",
          "Passwords",
          "Usernames"
        ],
        "Description": "In mid-2018, the online photography community <a href=\"https://support.500px.com/hc/en-us/articles/360017752493-Security-Issue-February-2019-FAQ\" target=\"_blank\" rel=\"noopener\">500px suffered a data breach</a>. The incident exposed almost 15 million unique email addresses alongside names, usernames, genders, dates of birth and either an MD5 or bcrypt password hash. In 2019, <a href=\"https://www.theregister.co.uk/2019/02/11/620_million_hacked_accounts_dark_web/\" target=\"_blank\" rel=\"noopener\">the data appeared listed for sale on a dark web marketplace</a> (along with several other large breaches) and subsequently began circulating more broadly. The data was provided to HIBP by a source who requested it to be attributed to &quot;BenjaminBlue@exploit.im&quot;.",
        "Domain": "500px.com",
        "IsFabricated": false,
        "IsRetired": false,
        "IsSensitive": false,
        "IsSpamList": false,
        "IsVerified": true,
        "LogoPath": "https://haveibeenpwned.com/Content/Images/PwnedLogos/500px.png",
        "ModifiedDate": "2019-03-25T08:01:09Z",
        "Name": "500px",
        "PwnCount": 14867999,
        "Title": "500px"
      },
      {
        "AddedDate": "2018-10-05T19:14:11Z",
        "BreachDate": "2018-07-23",
        "DataClasses": [
          "Email addresses",
          "Employers",
          "Geographic locations",
          "Job titles",
          "Names",
          "Phone numbers",
          "Salutations",
          "Social media profiles"
        ],
        "Description": "In July 2018, the sales engagement startup <a href=\"https://www.wired.com/story/apollo-breach-linkedin-salesforce-data/\" target=\"_blank\" rel=\"noopener\">Apollo left a database containing billions of data points publicly exposed without a password</a>. The data was discovered by security researcher <a href=\"http://www.vinnytroia.com/\" target=\"_blank\" rel=\"noopener\">Vinny Troia</a> who subsequently sent a subset of the data containing 126 million unique email addresses to Have I Been Pwned. The data left exposed by Apollo was used in their &quot;revenue acceleration platform&quot; and included personal information such as names and email addresses as well as professional information including places of employment, the roles people hold and where they're located. Apollo stressed that the exposed data did not include sensitive information such as passwords, social security numbers or financial data. <a href=\"https://www.apollo.io/contact\" target=\"_blank\" rel=\"noopener\">The Apollo website has a contact form</a> for those looking to get in touch with the organisation.",
        "Domain": "apollo.io",
        "IsFabricated": false,
        "IsRetired": false,
        "IsSensitive": false,
        "IsSpamList": false,
        "IsVerified": true,
        "LogoPath": "https://haveibeenpwned.com/Content/Images/PwnedLogos/Apollo.png",
        "ModifiedDate": "2018-10-23T04:01:48Z",
        "Name": "Apollo",
        "PwnCount": 125929660,
        "Title": "Apollo"
      },
      {
        "AddedDate": "2017-10-06T06:31:50Z",
        "BreachDate": "2014-05-08",
        "DataClasses": ["Email addresses", "Passwords", "Usernames"],
        "Description": "In May 2014, the link management company <a href=\"https://bitly.com/blog/urgent-security-update-regarding-your-bitly-account/\" target=\"_blank\" rel=\"noopener\">Bitly announced they'd suffered a data breach</a>. The breach contained over 9.3 million unique email addresses, usernames and hashed passwords, most using SHA1 with a small number using bcrypt.",
        "Domain": "bitly.com",
        "IsFabricated": false,
        "IsRetired": false,
        "IsSensitive": false,
        "IsSpamList": false,
        "IsVerified": true,
        "LogoPath": "https://haveibeenpwned.com/Content/Images/PwnedLogos/Bitly.png",
        "ModifiedDate": "2017-10-06T08:05:10Z",
        "Name": "Bitly",
        "PwnCount": 9313136,
        "Title": "Bitly"
      },
      {
        "AddedDate": "2014-02-15T11:24:42Z",
        "BreachDate": "2014-02-15",
        "DataClasses": [
          "Email addresses",
          "Passwords",
          "User website URLs",
          "Usernames"
        ],
        "Description": "In February 2014, the Forbes website <a href=\"http://news.cnet.com/8301-1009_3-57618945-83/syrian-electronic-army-hacks-forbes-steals-user-data\" target=\"_blank\" rel=\"noopener\">succumbed to an attack that leaked over 1 million user accounts</a>. The attack was attributed to the Syrian Electronic Army, allegedly as retribution for a perceived \"Hate of Syria\". The attack not only leaked user credentials, but also resulted in the posting of fake news stories to forbes.com.",
        "Domain": "forbes.com",
        "IsFabricated": false,
        "IsRetired": false,
        "IsSensitive": false,
        "IsSpamList": false,
        "IsVerified": true,
        "LogoPath": "https://haveibeenpwned.com/Content/Images/PwnedLogos/Forbes.png",
        "ModifiedDate": "2014-02-15T11:24:42Z",
        "Name": "Forbes",
        "PwnCount": 1057819,
        "Title": "Forbes"
      },
      {
        "AddedDate": "2019-02-21T19:28:46Z",
        "BreachDate": "2018-02-01",
        "DataClasses": [
          "Email addresses",
          "IP addresses",
          "Passwords",
          "Usernames"
        ],
        "Description": "In February 2018, the diet and exercise service <a href=\"https://content.myfitnesspal.com/security-information/FAQ.html\" target=\"_blank\" rel=\"noopener\">MyFitnessPal suffered a data breach</a>. The incident exposed 144 million unique email addresses alongside usernames, IP addresses and passwords stored as SHA-1 and bcrypt hashes (the former for earlier accounts, the latter for newer accounts). In 2019, <a href=\"https://www.theregister.co.uk/2019/02/11/620_million_hacked_accounts_dark_web/\" target=\"_blank\" rel=\"noopener\">the data appeared listed for sale on a dark web marketplace</a> (along with several other large breaches) and subsequently began circulating more broadly. The data was provided to HIBP by a source who requested it to be attributed to &quot;BenjaminBlue@exploit.im&quot;.",
        "Domain": "myfitnesspal.com",
        "IsFabricated": false,
        "IsRetired": false,
        "IsSensitive": false,
        "IsSpamList": false,
        "IsVerified": true,
        "LogoPath": "https://haveibeenpwned.com/Content/Images/PwnedLogos/MyFitnessPal.png",
        "ModifiedDate": "2019-02-21T20:00:56Z",
        "Name": "MyFitnessPal",
        "PwnCount": 143606147,
        "Title": "MyFitnessPal"
      },
      {
        "AddedDate": "2016-05-29T22:59:04Z",
        "BreachDate": "2013-02-28",
        "DataClasses": ["Email addresses", "Passwords"],
        "Description": "In early 2013, <a href=\"https://staff.tumblr.com/post/144263069415/we-recently-learned-that-a-third-party-had\" target=\"_blank\" rel=\"noopener\">tumblr suffered a data breach</a> which resulted in the exposure of over 65 million accounts. The data was later put up for sale on a dark market website and included email addresses and passwords stored as salted SHA1 hashes.",
        "Domain": "tumblr.com",
        "IsFabricated": false,
        "IsRetired": false,
        "IsSensitive": false,
        "IsSpamList": false,
        "IsVerified": true,
        "LogoPath": "https://haveibeenpwned.com/Content/Images/PwnedLogos/Tumblr.png",
        "ModifiedDate": "2016-05-29T22:59:04Z",
        "Name": "Tumblr",
        "PwnCount": 65469298,
        "Title": "tumblr"
      }
    ],
    "email": "ajgask@gmail.com",
    "firstName": "Adam",
    "lastLogin": "2019-12-16T07:36:41.900Z",
    "lastName": "Gask"
  }
  
  return (

        <div className={classes.root}>

            <div>
              <Container>
                <Grid
                  container
                  direction="row"
                  spacing={3}
                  className={classes.contentContainer}
                >
                  <Grid item container direction="column" spacing={3} md={9}>
                    <Grid item container spacing={2} direction="column">
                      <Grid item container md={12} spacing={2}>
                        <Grid item>
                          <img src={IconWelcome} className={classes.ydLogo} />
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="h4"
                            component="h1"
                            className={classes.welcomeText}
                          >
                            Welcome {authUser.firstName} {authUser.lastName}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item md={12}>
                        <div className={classes.divider}></div>
                      </Grid>
                      <Grid item md={12}>
                        <Paper className={classes.breachesPaper}>
                          <Grid item container spacing={2} direction="row">
                            <Grid item md={2}>
                              <Typography
                                variant="h2"
                                className={classes.breachesBigText}
                              >
                                {breaches.length}
                                <span className={classes.breachesText}>
                                  Breaches
                                </span>
                              </Typography>
                            </Grid>
                            <Grid item md={10}>
                              <p className={classes.titleText}>Summary</p>
                              <p className={classes.summaryText}>
                                The email address associated with your account (
                                <span className={classes.summaryTextBold}>
                                  {authUser.email}
                                </span>
                                ) has been breached {breaches.length} times.
                              </p>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid item container spacing={2} direction="column">
                      <Grid item container md={12} spacing={2}>
                        <Grid item>
                          <img src={IconBreaches} className={classes.ydLogo} />
                        </Grid>
                        <Grid item>
                          <Typography variant="h4" component="h1">
                            Breaches
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item md={12}>
                        <div className={classes.divider}></div>
                      </Grid>
                      <Grid item container md={12} spacing={2}>
                        {breaches.map(breach => (
                          <Grid item key={breach.Title} xs={12} md={12}>
                            <CardActionArea component="a" href="#">
                              <Card className={classes.card}>
                                <div className={classes.cardDetails}>
                                  <CardContent>
                                    <Grid
                                      container
                                      direction="column"
                                      spacing={2}
                                    >
                                      <Grid
                                        item
                                        container
                                        direction="row"
                                        spacing={3}
                                        md={12}
                                      >
                                        <Grid item md={3}>
                                          <CardMedia
                                            component="img"
                                            className={classes.cardMedia}
                                            image={breach.LogoPath}
                                            title="Image title"
                                          />
                                        </Grid>
                                        <Grid
                                          item
                                          container
                                          direction="column"
                                          md={9}
                                        >
                                          <Grid item>
                                            <Typography
                                              component="h2"
                                              variant="h4"
                                              className={classes.titleText}
                                            >
                                              {breach.Title}
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Grid
                                              container
                                              direction="row"
                                              spacing={2}
                                            >
                                              <Grid item>
                                                <Typography
                                                  component="h2"
                                                  variant="h4"
                                                  className={
                                                    classes.summaryText
                                                  }
                                                >
                                                  <span
                                                    className={[
                                                      classes.summaryText,
                                                      classes.summaryTextBold
                                                    ].join(" ")}
                                                  >
                                                    Breach date:
                                                  </span>{" "}
                                                  <Moment format="D MMM YYYY">
                                                    {breach.BreachDate}
                                                  </Moment>
                                                </Typography>
                                              </Grid>
                                              <Grid item>
                                                <Typography
                                                  component="h2"
                                                  variant="h4"
                                                  className={
                                                    classes.summaryText
                                                  }
                                                >
                                                  <span
                                                    className={[
                                                      classes.summaryText,
                                                      classes.summaryTextBold
                                                    ].join(" ")}
                                                  >
                                                    Breach size:
                                                  </span>{" "}
                                                  <NumberFormat
                                                    value={breach.PwnCount}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                  />
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      <Grid item md={12}>
                                        <div className={classes.divider}></div>
                                      </Grid>
                                      <Grid item md={12}>
                                        <Typography
                                          variant="body1"
                                          className={
                                            classes.breachDescriptionText
                                          }
                                          dangerouslySetInnerHTML={{
                                            __html: sanitizer(
                                              breach.Description
                                            )
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                </div>
                              </Card>
                            </CardActionArea>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item container md={3}>
                    <Grid item>
                      <Card className={classes.upsell}>
                        <Grid
                          container
                          className={classes.upsellGrid}
                          spacing={3}
                        >
                          <Grid container item direction="column" spacing={3}>
                            <Grid item>
                              <Typography
                                variant="h2"
                                className={[
                                  classes.upsellh2,
                                  classes.upsellh1
                                ].join(" ")}
                              >
                                Are you concerned about your wider data?
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography
                                variant="h3"
                                className={classes.upsellh2}
                              >
                                Unlock your full report to reveal all personal data compromises.
                              </Typography>
                            </Grid>

                            <UpsellPrice></UpsellPrice>
                          </Grid>
                          <Grid item container spacing={3}>
                            <Grid item>
                              <Typography
                                variant="h4"
                                className={classes.upsellFeaturesTitle}
                              >
                                Features
                              </Typography>
                            </Grid>
                            <Grid item style={{ width: "100%" }}>
                              <div
                                className={classes.upsellFeaturesDivider}
                              ></div>
                            </Grid>
                            <Grid item className={classes.upsellFeaturesList}>
                              <ul>
                                <li>Email addresses</li>
                                <li>Telephone numbers</li>
                                <li>Credit/Debit card numbers</li>
                                <li>Bank account numbers</li>
                                <li>Driver's license numbers</li>
                                <li>Passport numbers</li>
                                <li>National identification numbers</li>
                                <li>Retail credit cards</li>
                                <li>
                                  International Bank Account Numbers (IBAN)
                                </li>
                              </ul>
                            </Grid>

                            <Grid item style={{ width: "100%" }}>
                              <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.upsellButton}
                                onClick={() => {
                                  props.firebase
                                    .user(userContext.uid)
                                    .set(
                                      { registeredInterest: true },
                                      { merge: true }
                                    )
                                    .then(() => {
                                      alert(
                                        "Thank you for your interest, we will contact you soon!"
                                      );
                                    });
                                }}
                              >
                                Upgrade now
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </div>

        </div>
 
  );
}


export default compose(
  withFirebase
)(Home);
