import * as ROUTES from "../../constants/routes";
import React, {  } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import "./index.css";

function DiscountPage(props) {
  localStorage.setItem('discount', '£2.49');
  props.history.push(ROUTES.HOME);

  return <div></div>;
}

export default compose(
  withRouter,
)(DiscountPage);
