import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { useContext, useState } from "react";
import { withRouter, Redirect } from "react-router-dom";
import { compose } from "recompose";
import SignupBG from "../../images/signup-bg.jpg";
import UpsellLI from "../../images/upsell-li.svg";
import LogoWhite from "../../images/ydentity-logo-white.png";
import { withFirebase } from "../Firebase";
import "./index.css";
import { AuthUserContext, withAuthorization } from "../Session";
import Select from 'react-select'
import countryList from 'react-select-country-list'
import CircularProgress from '@material-ui/core/CircularProgress';
import UpsellPrice from "../UpsellPrice";
import * as ROUTES from "../../constants/routes";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://ydentity.com">
        Ydentity
      </Link>{" "}
      {new Date().getFullYear()}
      {": All right reserved"}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh"
  },
  image: {
    backgroundImage: "url(" + SignupBG + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  paper: {
    margin: theme.spacing(12, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    height: "50px",
    borderRadius: "3px",
    backgroundColor: "#0b8af0",
    margin: theme.spacing(3, 0, 2)
  },
  ydLogo: {
    width: "134.5px",
    height: "40.2px",
    marginBottom: "30px"
  },
  bigAvatar: {
    margin: theme.spacing(1),
    width: 60,
    height: 60
  },
  loginTitle: {
    marginBottom: theme.spacing(3)
  },
  h1: {
    fontFamily: "Lato",
    fontSize: "35px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#2a2a2a"
  },
  errorMessage: {
    color: "red"
  },
  oneStep: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff",
    marginLeft: "80px"
  },
  oneStepMore: {
    fontSize: "35px",
    fontWeight: 500,
    marginBottom: "30px"
  },
  div1: {
    display: "table",
    height: "80%"
  },
  div2: {
    display: "table-cell",
    verticalAlign: "middle"
  },
  div3: {
    textAlign: "left"
  },

  upsell: {
    width: "100%",
    marginTop: '24px'
  },
  upsellh2: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellh1: {
    fontSize: "35px",
    fontWeight: 500
  },
  upsellGrid: {
    padding: theme.spacing(6, 4),
    position: 'relative'
  },
  upsellPrice: {
    fontFamily: "Lato",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellLargeText: {
    fontSize: "64px"
  },
  upsellMonthlyText: {
    fontSize: "24px"
  },
  upsellFeaturesDivider: {
    height: "1px",
    opacity: 0.3,
    backgroundColor: "#ffffff"
  },
  upsellFeaturesTitle: {
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellFeaturesList: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.88,
    letterSpacing: "normal",
    color: "#ffffff",
    listStyleImage: "url(" + UpsellLI + ")"
  },

  upsellFeaturesUl: {
    paddingLeft: '20px'
  },

  upsellButton: {
    height: "50px",
    borderRadius: "3px",
    backgroundColor: "#0b8af0",
    margin: theme.spacing(3, 0, 2)
  },

  vr: {
    position: 'absolute',
    borderRight: '1px solid #9adade',
    left: '50%',
    bottom: '100px',
    top: '100px'
  },

  vrWrapper: {
    position: 'relative'
  }
}));

function SubscribeToPremium(props) {
  const classes = useStyles();

  let [submitting, setSubmitting] = useState(false);
  let [cardNumber, setCardNumber] = useState(null);
  let [expiry, setExpiry] = useState(null);
  let [cvc, setCVC] = useState(null);
  let [name, setName] = useState(null);
  let [street, setStreet] = useState(null);
  let [cityTown, setCityTown] = useState(null);
  let [countyState, setCountyState] = useState(null);
  let [postCode, setPostCode] = useState(null);
  let [country, setCountry] = useState({value: 'GB', label: 'United Kingdom'});
  let [coupon, setCoupon] = useState(null);
  let [error, setError] = useState(null);
  let [countryOptions, setCountryOptions] = useState(countryList().getData());
  let [subscribed, setSubscribed] = useState(false);

  const userContext = useContext(AuthUserContext);

  const onSubmit = async event => {
    event.preventDefault();

    if (
      cardNumber == null ||
      expiry == null ||
      cvc == null ||
      name == null
    ) {
      return;
    }

    var expirySplit = expiry.split('/');

    setSubmitting(true);
    setError(null);

    const result = await props.firebase.subscribeToPremium({
      cardNumber: cardNumber,
      expiry: {
        month: expirySplit[0],
        year: expirySplit[1]
      },
      cvc: cvc,
      accountName: name,
      city: cityTown,
      country: country.value,
      street: street,
      postCode: postCode,
      state: countyState,
      coupon: coupon
    });

    const response = result.data.response;

    if (response && response.error) {
      if (response.error == 'requires_action') {
        var stripe = window.Stripe(process.env.REACT_APP_STRIPE_KEY);

        // This can be found on invoice.payment_intent.client_secret
        var paymentIntentSecret = response.paymentIntentSecret;
        stripe.confirmCardPayment(paymentIntentSecret).then(function (result) {
          if (result.error) {
            setError({ error: { message: 'Payment method failed' } });
          } else {
            window.location = '/'
          }
        });
      } else
        setError({ error: { message: response.error } });

      setSubmitting(false)
      return;
    }

    window.location = '/'
  };

  const onChangeCardNumber = event => {
    let { name, value } = event.target;
    setCardNumber(value);
  };
  const onChangeExpiry = event => {
    let { name, value } = event.target;
    setExpiry(value);
  };
  const onChangeCvc = event => {
    let { name, value } = event.target;
    setCVC(value);
  };
  const onChangeName = event => {
    let { name, value } = event.target;
    setName(value);
  };
  const onChangeStreet = event => {
    let { name, value } = event.target;
    setStreet(value);
  };
  const onChangeCityTown = event => {
    let { name, value } = event.target;
    setCityTown(value);
  };
  const onChangeCountyState = event => {
    let { name, value } = event.target;
    setCountyState(value);
  };
  const onChangePostCode = event => {
    let { name, value } = event.target;
    setPostCode(value);
  };
  const onChangeCountry = event => {
    setCountry(event);
  };
  const onChangeCoupon = event => {
    let { name, value } = event.target;
    setCoupon(value);
  }

  props.firebase.getUser(userContext.uid).then(user => {
    if (user.subscription && user.subscription.active) {
      window.location = '/'
      window.location.reload();
    }
  });

  return (
    <AuthUserContext.Consumer>
      {authUser => (
  <Grid container component="main" className={classes.root}>
        <Hidden smDown>
    <Grid item md={7} className={classes.image}>
        <div className={classes.div1}>
          <div className={classes.div2}>
            <div className={classes.div3}>
              <img
                src={LogoWhite}
                className={classes.ydLogo}
                style={{ marginLeft: "80px" }}
              />
              <Grid item>
                <Grid
                  container
                  className={classes.upsellGrid}
                  spacing={10}
                  justify="center"
                >
                  <Grid container item direction="column" spacing={3} md={6} style={{ paddingRight: '80px' }}>
                    <Grid item>
                      <Typography
                        variant="h2"
                        className={[
                          classes.upsellh2,
                          classes.upsellh1
                        ].join(" ")}
                      >
                        Unlock your full report now
                      </Typography>
                    </Grid>

                    <UpsellPrice></UpsellPrice>
                  </Grid>
                  <div className={classes.vr}></div>
                  <Grid item container spacing={0} md={5} className={classes.upsellRight}>
                    <Grid item>
                      <Typography
                        variant="h4"
                        className={classes.upsellFeaturesTitle}
                      >
                        Features
                      </Typography>
                    </Grid>
                    <Grid item style={{ width: "100%", marginTop: '15px' }}>
                      <div
                        className={classes.upsellFeaturesDivider}
                      ></div>
                    </Grid>
                    <Grid item className={classes.upsellFeaturesList}>
                      <ul className={classes.upsellFeaturesUl}>
                        <li>Email addresses</li>
                        <li>Telephone numbers</li>
                        <li>Credit/Debit card numbers</li>
                        <li>Bank account numbers</li>
                        <li>Driver's license numbers</li>
                        <li>Passport numbers</li>
                        <li>National identification numbers</li>
                        <li>Retail credit cards</li>
                        <li>
                          International Bank Account Numbers (IBAN)
                        </li>
                      </ul>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </Grid>
      </Hidden>
      {subscribed ? 
      <Grid item xs={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            className={[classes.h1, classes.loginTitle].join(" ")}
          >
            Thank you for your purchase
          </Typography>
          <Link
            component={Link}
            href={ROUTES.HOME}
            color="inherit"
            className={classes.signOut}
          >
            Back to dashboard
          </Link>
        </div>
      </Grid>
      :
      <Grid item xs={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Typography
            component="h1"
            variant="h5"
            className={[classes.h1, classes.loginTitle].join(" ")}
          >
            Purchase Full Report
          </Typography>

          {error && (
            <p className={classes.errorMessage}>{error.error.message}</p>
          )}

          <form className={classes.form} onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={submitting}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="nameOnCard"
                  onChange={onChangeName}
                  label="Name on card"
                  name="Name on card"
                  autoComplete="cc-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={submitting}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="cardNumber"
                  onChange={onChangeCardNumber}
                  label="Card number"
                  name="cardNumber"
                  autoComplete="cc-number"
                  placeholder="XXXX-XXXX-XXXX-XXXX"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={submitting}
                  variant="outlined"
                  margin="normal"
                  required
                  onChange={onChangeExpiry}
                  fullWidth
                  name="Card Expiry"
                  label="Card Expiry"
                  id="cardExpiry"
                  autoComplete="cc-exp"
                  placeholder="XX/XX"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  disabled={submitting}
                  variant="outlined"
                  margin="normal"
                  required
                  onChange={onChangeCvc}
                  fullWidth
                  name="CVC"
                  label="CVC"
                  id="cvc"
                  autoComplete="cc-csc"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={submitting}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="street"
                  onChange={onChangeStreet}
                  label="Street"
                  name="Street"
                  autoComplete="billing street-address"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={submitting}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="cityTown"
                  onChange={onChangeCityTown}
                  label="City / Town"
                  name="City / Town"
                  autoComplete="billing address-level2"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={submitting}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="countyState"
                  onChange={onChangeCountyState}
                  label="County / State"
                  name="County / State"
                  autoComplete="billing address-level1"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={submitting}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="postCode"
                  onChange={onChangePostCode}
                  label="Postcode / Zip"
                  name="Postcode / Zip"
                  autoComplete="billing postal-code"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Select 
                  disabled={submitting}
                  options={countryOptions} 
                  onChange={onChangeCountry}
                  value={country}
                  required
                  variant="outlined"
                  margin="normal"
                  autoComplete="billing country"
                  label="Country"
                  name="Country"
                  className='styled-select'>
                </Select>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={submitting}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="coupon"
                  onChange={onChangeCoupon}
                  label="Coupon Code"
                  name="Coupon Code"
                />
              </Grid> 

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value="acceptTandCs"
                      color="primary"
                      required
                    />
                  }
                  label={
                    <div style={{ zIndex: 3 }}>
                      <span>I accept the </span>{" "}
                      <Link href={"https://id.ydentity.com/terms-and-conditions"} target="_blank">terms &amp; conditions</Link>
                    </div>
                  }
                />
                <Grid item className="MuiTypography-root MuiFormControlLabel-label MuiTypography-body1">

                </Grid>
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={submitting}
              className={classes.submit}
            >
              {submitting ? <CircularProgress /> : <React.Fragment>Show my report now</React.Fragment>}
            </Button>

            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    }
    </Grid>
      )}
    </AuthUserContext.Consumer>
  );
}

const condition = authUser => !!authUser;

export default compose(
  withRouter,
  withAuthorization(condition),
  withFirebase
)(SubscribeToPremium);
