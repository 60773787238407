import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconWelcome from "../../../images/icon-welcome.svg";
import UpsellBG from "../../../images/upsell-bg.jpg";
import UpsellLI from "../../../images/upsell-li.svg";
import { ReactSVG } from "react-svg";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import * as ROUTES from "../../../constants/routes";
import UpsellPrice from "../../UpsellPrice";
import HowItWorks from "../../../images/how-it-works.png";

const useStyles = makeStyles(theme => ({
  contentContainer: {
    paddingTop: theme.spacing(3)
  },

  divider: {
    height: "1px",
    backgroundColor: "#e9e9e9"
  },

  noBreachesPaper: {
    padding: theme.spacing(4, 4),
    position: 'relative',
    overflow: 'hidden',
    margin: '24px 8px'
  },

  noBreachesSideRect: {
    position: 'absolute',
    backgroundColor: '#05c3be',
    width: '15px',
    left: '0px',
    bottom: '0px',
    top: '0px'
  },

  summaryText: {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "rgba(35, 38, 43, 0.65)",
    marginTop: '5px',
    marginBottom: '5px'
  },
  summaryTextBold: {
    fontWeight: 500
  },
  trophy: {
    width: '74px',
    height: '74px',
    objectFit: 'contain'
  },
  goodNews: {
    width: '100%'
  },

  upsell: {
    backgroundImage: "url(" + UpsellBG + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "175%",
    backgroundPosition: "90% 10%",
    background: "#05c3be",
    width: "100%",
    marginTop: '24px'
  },
  upsellh2: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellh1: {
    fontSize: "35px",
    fontWeight: 500
  },
  upsellGrid: {
    padding: theme.spacing(6, 4),
    position: 'relative'
  },
  upsellPrice: {
    fontFamily: "Lato",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellLargeText: {
    fontSize: "64px"
  },
  upsellMonthlyText: {
    fontSize: "24px"
  },
  upsellFeaturesDivider: {
    height: "1px",
    opacity: 0.3,
    backgroundColor: "#ffffff"
  },
  upsellFeaturesTitle: {
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellFeaturesList: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.88,
    letterSpacing: "normal",
    color: "#ffffff",
    listStyleImage: "url(" + UpsellLI + ")"
  },

  upsellFeaturesUl: {
    paddingLeft: '20px'
  },

  upsellButton: {
    height: "50px",
    borderRadius: "3px",
    backgroundColor: "#0b8af0",
    margin: theme.spacing(3, 0, 2)
  },

  vr: {
    position: 'absolute',
    borderRight: '1px solid #9adade',
    left: '50%',
    bottom: '100px',
    top: '100px'
  },

  vrWrapper: {
    position: 'relative'
  },

  welcomeText: {
    fontFamily: "Lato",
    fontSize: "35px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  title: {
    background: 'linear-gradient(to bottom, #1693b9, #03337d)',
    width: '100%',
    color: 'white',
    height: 'fit-content',
    textAlign: 'center',
    fontFamily: 'Lato',
    fontWeight: 'bold',
    padding: '10px 0px',
  },

  wrapper: {
    height: 'fit-content'
  },

  imageWrapper: {
    width: '100%'
  },

  image: {
    width: '100%'
  },

  blurb: {
    padding: '10px'
  },

  faqQ: {
    fontWeight: 'bold'
  },

  faqA: {
    marginLeft: '0px',
    paddingBottom: '15px'
  }  
}));

const FAQ = (props) => {
  const classes = useStyles();

  return <Grid item container md={3} className={classes.wrapper}>
          <div className={classes.title}>
            <Typography variant="h5" component="h3">FAQ</Typography>
          </div>

          <dl className={classes.faq}>
            <dt className={classes.faqQ}>How does Ydentity's Cybercheck work?</dt>
            <dd className={classes.faqA}>Ydentity Cybercheck uses skilled human analysts to research and harvest compromised data  from over 1,000 criminal forums, websites and chatrooms. These websites frequently close  and move to avoid detection. Our analysts are skilled in monitoring them – and in identifying  and avoiding the false data criminals use to mislead researchers. This expert approach gives  Ydentity Cybercheck a unique advantage over machine-driven searching.</dd>

            <dt className={classes.faqQ}>What does it mean if my password or other details is on the  dark web?</dt>
            <dd className={classes.faqA}>Criminals may use it to try to hack into your accounts. They could then use your details to  apply for credit in your name or commit other types of fraud.</dd>

            <dt className={classes.faqQ}>If my personal details has been compromised, what actions  should I take?</dt>
            <dd className={classes.faqA}>Discovering your personal details are available online can be unsettling but the reality is this  is low-risk. Directories are commonplace online and you don't need to take any immediate  action.</dd>

            <dt className={classes.faqQ}>If my credit/debit has been compromised, what actions  should I take?</dt>
            <dd className={classes.faqA}>Immediately contact your banking provider and ensure the compromised card is cancelled  and replaced. Your card details are available to others, so it's essential you action this  immediately.</dd>

            <dt className={classes.faqQ}>If my email has been compromised, what actions should I  take?</dt>
            <dd className={classes.faqA}>Immediately change the password for the email address that was found compromised. If you  use that email for online accounts, you will also want to change the passwords on those  accounts. </dd>

            <dt className={classes.faqQ}>If my passport number has been compromised, what  actions should I take?</dt>
            <dd className={classes.faqA}>Contact the Government Lost &amp; Stolen portal immediately. Your personal details are at risk  and you must replace the existing passport: <a href="https://www.gov.uk/report-a-lost-or-stolen">https://www.gov.uk/report-a-lost-or-stolen</a> passport</dd>

            <dt className={classes.faqQ}>If I'm unusure of how to action other compromised data? </dt>
            <dd className={classes.faqA}>Contact us direct for further support: <a href="mailto:contact@ydentity.com" target="_blank">contact@ydentity.com</a></dd>
          </dl>
        </Grid>
};

export default FAQ;
