import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconBreaches from "../../../../images/icon-breaches.svg";
import dompurify from "dompurify";
import UpsellBG from "../../../../images/upsell-bg.jpg";
import UpsellLI from "../../../../images/upsell-li.svg";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#fafafa",
    flexGrow: 1,
    position: 'relative'
  },
  contentContainer: {
    paddingTop: theme.spacing(3)
  },
  noRootPadding: {
    paddingBottom: '16px !important'
  },

  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: "flex"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: "80%",
    height: "75px",
    borderRadius: "8px",
    padding: "20px",
    objectFit: "scale-down",
    background: "#e0e0e0"
  },
  divider: {
    height: "1px",
    backgroundColor: "#e9e9e9"
  },

  breachesBigText: {
    fontSize: "54px",
    fontWeight: 500
  },

  breachesPaper: {
    padding: theme.spacing(3, 2)
  },
  breachesText: {
    fontFamily: "Lato",
    fontSize: "20px",
    display: "block",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  summaryText: {
    fontFamily: "Lato",
    fontSize: "18px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.5,
    letterSpacing: "normal",
    color: "rgba(35, 38, 43, 0.65)"
  },
  summaryTextBold: {
    fontWeight: 500
  },
  breachDateText: {
    fontWeight: 300
  },
  breachDescriptionText: {
    opacity: 0.65,
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: 300,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.69,
    letterSpacing: "normal",
    color: "#23262b"
  },

  upsell: {
    backgroundImage: "url(" + UpsellBG + ")",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    background: "#05c3be",
    width: "100%"
  },
  upsellh2: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellh1: {
    fontSize: "35px",
    fontWeight: 500
  },
  upsellGrid: {
    padding: theme.spacing(4, 4)
  },
  upsellPrice: {
    fontFamily: "Lato",
    fontSize: "36px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1,
    letterSpacing: "normal",
    textAlign: "center",
    color: "#ffffff"
  },
  upsellLargeText: {
    fontSize: "64px"
  },
  upsellMonthlyText: {
    fontSize: "24px"
  },
  upsellFeaturesDivider: {
    height: "1px",
    opacity: 0.3,
    backgroundColor: "#ffffff"
  },
  upsellFeaturesTitle: {
    fontFamily: "Lato",
    fontSize: "20px",
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  upsellFeaturesList: {
    fontFamily: "Lato",
    fontSize: "16px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.88,
    letterSpacing: "normal",
    color: "#ffffff",
    listStyleImage: "url(" + UpsellLI + ")"
  },

  upsellButton: {
    height: "50px",
    borderRadius: "3px",
    backgroundColor: "#0b8af0",
    margin: theme.spacing(3, 0, 2)
  },

  welcomeText: {
    fontFamily: "Lato",
    fontSize: "35px",
    fontWeight: "300",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  titleText: {
    fontFamily: "Lato",
    fontSize: "24px",
    fontWeight: "500",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#2a2a2a"
  },

  horizontalDl: {
    display: 'grid',
    gridTemplateColumns: '150px auto',
    margin: 0,
    gap: '10px'
  }
}));

const BreachBreakdown = (props) => {
  const classes = useStyles();

  const userSubscribed = () => {
    return props.user.subscription && props.user.subscription.active;
  }

  return <Grid xs={12} item>
          <Grid item container xs={12} spacing={2}>
            <Grid item>
              <img src={IconBreaches} className={classes.ydLogo} />
            </Grid>
            <Grid item>
              <Typography variant="h4" component="h1">
                {props.breachTitle}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={12} style={{paddingTop: '8px', paddingBottom: '8px'}}>
            <div className={classes.divider}></div>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            {props.breachList.map((breach, index) => (
              <Grid item key={`${props.breachType}_${index}`} xs={12}>
                <Card className={classes.card}>
                  <div className={classes.cardDetails}>
                    <CardContent className={index != props.breachList.length - 1 ? classes.noRootPadding : null}>
                      <Grid
                        container
                        direction="column"
                        spacing={2}
                      >
                        <Grid
                          item
                          container
                          direction="row"
                          spacing={3}
                          md={12}
                        >
                          <Grid
                            item
                            container
                            direction="column"
                            md={9}
                          >
                            <Grid item>
                              <Grid>
                                <dl className={classes.horizontalDl}>
                                  {props.breachBreakdown.map(breakdown =>
                                    <React.Fragment key={`${props.breachType}_${index}_${breakdown['key']}`}>
                                      {breach[breakdown['key']] ?
                                        <React.Fragment>
                                          <dt style={{fontWeight: 'bold'}}>
                                            {breakdown['title']}:
                                          </dt>
                                          {breakdown['obfs'] && !userSubscribed() ? 
                                          <dd style={{'color': 'transparent', textShadow: '0 0 5px rgba(0,0,0,0.5)'}}>Premium Exclusive</dd> :
                                          <dd>{!isNaN(new Date(breach[breakdown['key']]).getTime()) ? new Date(breach[breakdown['key']]).toLocaleDateString() : breach[breakdown['key']]}</dd>}
                                        </React.Fragment> : null}
                                    </React.Fragment>
                                  )}
                                </dl>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>

};

export default BreachBreakdown;
